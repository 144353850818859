import { ITEM_SELECIONADO, FORM_STATE } from "./actionTypes";
const initialState = {
  itemSelecionado: {},
  formState: ""
};

export const FormReducer = (state = initialState, action) => {
  switch (action.type) {
  case ITEM_SELECIONADO:
    return {
      ...state,
      itemSelecionado: action.payload
    };
  case FORM_STATE: 
    return {
      ...state,
      formState: action.payload
    };
  default:
    return state;
  }
};
