export const StatusCpr = {
  CADASTRADA: 5,
  VISTORIAAGENDADA: 6,
  VISTORIANDO: 2,
  AGUARDANDOCOLHEITA: 3,

  COLHENDO: 4,
  COLHEITASUPERVISIONADA: 7,
  COLHEITAFINALIZADA: 8,
  LIQUIDADA: 1,
};

export default StatusCpr;
