import BaseController from "../../components/BaseController";
import { default as CprController } from "../CadastroCpr/controller";
import { default as AnexoController } from "../CadastroCpr/AnexoController";
import { StatusCpr } from "../../utils/StatusCpr";

class Controller extends BaseController {
  constructor() {
    super("bashboard");
    this.cpfController = new CprController();
    this.anexoController = new AnexoController();
    this.areaTotal = 0;
    this.volumeOperacoes = 0;
  }
  async getMiniCards(
    listaCpr,
    anexos,
    colheita = {
      hectaresColhidos: 0,
      volumeTrading: 0,
      volumeArmProprio: 0,
      volumeDeOperacoesSilo: 0,
    },
  ) {
    let areaEfetiva = 0;
    const anexoEmRisco = anexos.filter(anexo => {
      if (anexo.areaEfetiva) {
        areaEfetiva += Number(anexo.areaEfetiva);
      }
      return anexo.emRisco;
    });

    const anexosArresto = anexos.filter(anexo => {
      return Number(anexo.tipoDocumento) === 4;
    });

    const codigoCprUnicaEmRisco = new Set();
    anexoEmRisco.forEach(anexo => {
      codigoCprUnicaEmRisco.add(anexo.cprCodigo);
    });

    const cprUnicaArresto = new Set();
    anexosArresto.forEach(anexo => {
      cprUnicaArresto.add(anexo.cprCodigo);
    });

    this.areaTotal = 0;
    this.volumeOperacoes = 0;
    let colhendo = 0;
    listaCpr.forEach(cpr => {
      this.areaTotal += Number(cpr.areatotal.replace(/[.]/g, "").replace(",", "."));
      this.volumeOperacoes += Number(cpr.volume.replace(/[.]/g, "").replace(",", "."));
      if (cpr.status === StatusCpr.COLHENDO) {
        colhendo++;
      }
    });

    return new Promise(r => {
      r([
        {
          title: "Operações",
          icon: "iconStack",
          valor: listaCpr.length,
          url: "#/operacoes",
          color: "#56d9fe",
          animatePath: true,
        },
        {
          title: "Area total",
          icon: "iconMap",
          valor: this.formatNumber(this.areaTotal),
          color: "#4bd991",
        },
        {
          title: "Area efetiva",
          icon: "iconMap",
          valor: this.formatNumber(areaEfetiva),
          color: "#4bd991",
        },
        {
          title: "Volume das operações das CPRs",
          icon: "iconVolOp",
          valor: this.formatNumber(this.volumeOperacoes),
          color: "#4bd991",
        },
        {
          title: "Volume colhido das operações",
          icon: "iconVolOp",
          valor: this.formatNumber(colheita.volumeDeOperacoesSilo),
          color: "#4bd991",
        },
        {
          title: "Em risco",
          icon: "iconRisk",
          url: "?emrisco=true/#/relatorio",
          valor: codigoCprUnicaEmRisco.size,
          color: "#ff6565",
        },
        {
          title: "Arresto",
          icon: "iconVolProp",
          valor: cprUnicaArresto.size,
          url: "?arresto=true/#/relatorio",
          color: "#ff6565",
        },
        {
          title: "Colheita em andamento",
          icon: "iconAndamento",
          valor: colhendo,
          color: "#a3a0fb",
        },
        {
          title: "Hectares Colhidos",
          icon: "iconMap",
          valor: this.formatNumber(colheita.hectaresColhidos),
          color: "#4bd991",
        },
        {
          title: "Volume da entrega na trading",
          icon: "iconVolOpTrade",
          valor: this.formatNumber(colheita.volumeTrading),
          color: "#ff6565",
        },
        {
          title: "Volume armazenamento próprio",
          icon: "iconVolProp",
          valor: this.formatNumber(colheita.volumeArmProprio),
          color: "#56d9fe",
        },
      ]);
    });
  }

  async getValorMilhoes(listaCpr, data = { hectaresColhidos: 0 }) {
    let valormonetario = 0;
    let atingido = 0;
    listaCpr.forEach(cpr => {
      if (cpr.valormonetario) {
        let valor = this.toNumber(cpr.valormonetario);
        valormonetario += valor;
      }

      if (cpr.valorSaca) {
        console.info(cpr.valorSaca);
        atingido += data.hectaresColhidos * this.toNumber(cpr.valorSaca);
      }
    });

    return { total: valormonetario, restante: valormonetario - atingido, atingido };
  }

  async getVolumeSacas(listaCpr) {
    this.volumeOperacoes = 0;
    listaCpr.forEach(cpr => {
      this.volumeOperacoes += Number(cpr.volume.replace(/[.]/g, "").replace(",", "."));
    });
    return { total: this.volumeOperacoes, restante: this.volumeOperacoes, atingido: 0 };
  }

  async getAreaColhidaCPR(listaCpr) {
    this.areaTotal = 0;
    listaCpr.forEach(cpr => {
      this.areaTotal += Number(cpr.areatotal.replace(/[.]/g, "").replace(",", "."));
    });

    return { total: this.areaTotal, colhido: 0 };
  }

  async getOperacoes(listaCpr, listaAnexos = []) {
    const data = {
      cadastrada: 0,
      liquidada: 0,
      vistoriando: 0,
      aguardandoColheita: 0,
      colhendo: 0,
      laudosenviados: 0,
      vistoriaAgendada: 0,
      colheitaSupervisionada: 0,
      total: listaCpr.length,
    };

    listaCpr.forEach(cpr => {
      if (cpr.status === StatusCpr.CADASTRADA || cpr.status === undefined) {
        data.cadastrada++;
      } else if (cpr.status === StatusCpr.VISTORIAAGENDADA) {
        data.vistoriaAgendada++;
      } else if (cpr.status === StatusCpr.VISTORIANDO) {
        data.vistoriando++;
      } else if (cpr.status === StatusCpr.AGUARDANDOCOLHEITA) {
        data.aguardandoColheita++;
      } else if (cpr.status === StatusCpr.COLHENDO) {
        data.colhendo++;
      } else if (cpr.status === StatusCpr.COLHEITASUPERVISIONADA) {
        data.colheitaSupervisionada++;
      } else if (cpr.status === StatusCpr.COLHEITAFINALIZADA) {
        data.colheitaFinalizada++;
      } else if (cpr.status === StatusCpr.LIQUIDADA) {
        data.liquidada++;
      }
    });

    // const codigoCprUnica = new Set();
    const anexos = listaAnexos.filter(anexo => {
      return Number(anexo.tipoDocumento) === 1;
    });

    data.laudosenviados = anexos.length;

    return data;
  }
}

export default Controller;
