import React from "react";
import Image from "react-bootstrap/Image";
import "./ImageUpload.scss";
import { Chip } from "@material-ui/core";
import { moment } from "../utils/TimeZone";
import { FindInPage } from "@material-ui/icons";

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { file: {}, imagePreviewUrl: "", type: "" };
  }

  componentDidMount() {
    // debugger;
    if (this.props.url) {
      this.setState({
        imagePreviewUrl: this.props.url,
      });
    }

    if (this.props.file) {
      this.setState({ file: this.props.file });
    }

    if (this.props.fisicName) {
      this.setState({ file: { ...this.state.file, name: this.props.fisicName } });
    }
  }

  componentDidUpdate(prevProps) {
    // debugger;
    if (this.props.url && !prevProps.url) {
      this.setState({
        imagePreviewUrl: this.props.url,
      });
    }

    if (prevProps.fisicName !== this.props.fisicName) {
      this.setState({ file: { ...this.props.file, name: this.props.fisicName } });
    }

    if (this.props.file && JSON.stringify(this.props.file) !== JSON.stringify(prevProps.file)) {
      let file = this.props.file;
      if (this.props.fisicName) {
        file.name = this.props.fisicName;
      }

      this.setState({ file, imagePreviewUrl: this.props.file.imagePreviewUrl });
    }
  }

  validateType(type, name) {
    if (type === "") {
      const split = name.split(".");
      type = "." + split[split.length - 1];
    }

    if (!type.match(new RegExp(this.props.acceptTypes, "gi"))) {
      if (this.props.onInvalid) {
        this.props.onInvalid();
      } else {
        alert("Arquivo inválido!");
      }
      return false;
    }

    return true;
  }

  _handleImageChange(e) {
    e.preventDefault();

    if (
      e.target.files.length === 0 ||
      !this.validateType(e.target.files[0].type, e.target.files[0].name)
    ) {
      let data = {
        file: null,
        imagePreviewUrl: null,
      };
      this.setState(data);
      this.props.handleChange({ data, name: null });
      return;
    }

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = e1 => {
      let data = {
        file: file,
        imagePreviewUrl: reader.result,
        type: file.type,
      };

      if (data.type === "") {
        const split = data.file.name.split(".");
        data.type = split[split.length - 1];
        data.type = data.type[0].toUpperCase() + data.type.substring(1);
      }

      this.setState(data);

      this.props.handleChange({ data, name: this.element.name });
    };

    reader.readAsDataURL(file);
  }

  render() {
    let { imagePreviewUrl } = this.state;
    let imagePreview = null;
    let typeImage = this.state.type.match(/image/gi);
    if (imagePreviewUrl) {
      imagePreview = (
        <div className="container-image">
          <div
            className="hover remove"
            onClick={e => {
              e.stopPropagation();
              let data = {
                file: null,
                imagePreviewUrl: null,
              };
              this.element.value = "";
              this.setState(data);
              this.props.handleChange({ data, name: null });
            }}
          >
            X
          </div>
          {/* {!typeImage && ( */}
          {typeImage && (
            <>
              <Image src={imagePreviewUrl} rounded />
              <div className="hover">Clique na imagem para altera-la!</div>
            </>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Chip style={{ marginTop: 5 }} label={"Nome: " + this.state.file.name} />
            {this.props.showSize && (
              <Chip
                style={{ marginTop: 5 }}
                label={"Tamanho " + (this.state.file.size / 1024 / 1024).toFixed(2) + "mb"}
              />
            )}
            {this.props.showModification && (
              <Chip
                style={{ marginTop: 5 }}
                label={
                  "Última modificação: " +
                  moment(this.state.file.lastModifiedDate).format("DD/MM/YYYY HH:mm:ss")
                }
              />
            )}
          </div>
          {/* )} */}
        </div>
      );
    } else {
      imagePreview = (
        <div>
          <div className="previewText">
            Clique aqui para selecionar{" "}
            {this.props.acceptTypes === "image" ? "uma imagem" : "um arquivo"}
          </div>
          <FindInPage
            style={{
              height: 100,
              width: 100,
              color: "#2f3c86",
            }}
          />
        </div>
      );
    }

    return (
      <div className="previewComponent">
        <input
          accept={this.props.acceptTypes.split("|").join(", ")}
          // maxLength={200}
          ref={e => {
            this.element = e;
          }}
          className="fileInput"
          type="file"
          name={this.props.name}
          onChange={e => this._handleImageChange(e)}
        />

        <div
          className="imgPreview rounded"
          onClick={() => {
            this.element.click();
          }}
        >
          {imagePreview}
        </div>
      </div>
    );
  }

  static propTypes = {
    handleChange: () => {},
  };

  static defaultProps = {
    acceptTypes: "image",
    showModification: true,
    showSize: true,
  };
}

export default ImageUpload;
