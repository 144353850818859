import BaseController from "../../components/BaseController";
import OperacoesService from "./service";

class Controller extends BaseController {
  constructor() {
    super("operacoes");
    this.service = new OperacoesService();
  }
  async getDataRisk() {
    //await this.sleep(300);
    return new Promise(r => {
      r({
        data: {
          months: ["jan", "fev", "mar", "abr", "maio"],
          value1: [0, 0, 0, 0],
          value2: [0, 0, 0, 0],
          // value1: [5500, 1400, 4200, 3000],
          // value2: [4000, 2000, 4000, 5200],
        },
      });
    });
  }

  getOperacoes(cprId, filtroSafra = "", geral = false) {
    return this.service.getDataFromCpr(cprId, filtroSafra, geral);
  }

  getDataStatistics(cprId, safra, geral = false) {
    return this.service.getDataStatistics(cprId, safra, geral);
  }

  async getMiniCards({
    cargas = 0,
    hectaresColhidos = 0,
    lavouras = 4, //quantidade de monitoramentos do tipo lavoura
    silo = 1, //quantidade de monitoramentos do tipo silo
    cargasArmProprio = 0,
    cargasTrading = 0,
    cargasDesvidas = 0,
    volumeDeOperacoesLavoura = 0,
    volumeDeOperacoesSilo = 0,

    volumeTrading = 0,
    volumeDesvidas = 0,
    volumeArmProprio = 0,
    volumeSemLocalDefinido = 0,
    cargasOntem = 0,
    cargasHoje = 0,
  } = {}) {
    return [
      {
        title: "Cargas totais",
        icon: "iconCarga",
        valor: cargas,
        color: "#56D9FE",
      },
      {
        title: "Cargas ontem",
        icon: "iconCarga",
        valor: cargasOntem,
        color: "#ff6565",
      },
      {
        title: "Cargas hoje",
        icon: "iconCarga",
        valor: cargasHoje,
        color: "#4bd991",
      },
      {
        title: "Hectares Colhidos",
        icon: "iconMap",
        valor: this.formatNumber(hectaresColhidos),
        color: "#4bd991",
      },
      {
        title: "Cargas Desviadas",
        icon: "iconCarga",
        valor: cargasDesvidas,
        color: "#ff6565",
      },
      {
        title: "Volume da operação (Sacas)",
        icon: "iconVolOp",
        valor: this.formatNumber(volumeDeOperacoesSilo),
        color: "#4bd991",
      },
      {
        title: "Volume da entrega na trading (Sacas)",
        icon: "iconVolOpTrade",
        valor: this.formatNumber(volumeTrading),
        color: "#ff6565",
      },
      {
        title: "Volume armaz. próprio (Sacas)",
        icon: "iconVolProp",
        valor: this.formatNumber(volumeArmProprio),
        color: "#56d9fe",
      },
    ];
  }
}

export default Controller;
