import React, { useState } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Paper from "@material-ui/core/Paper";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Chip from "@material-ui/core/Chip";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/pt-br";
// import { default as components } from "../components/SelectMult";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";

import Cleave from "cleave.js/react";

import Save from "@material-ui/icons/Save";
import { Tooltip, Typography, FormHelperText, Checkbox, IconButton } from "@material-ui/core";

import Endereco from "./Endereco";
import Swal from "sweetalert2";
import ImageUpload from "./ImageUpload";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  panel: {
    padding: theme.spacing(3, 2),
    marginBottom: 10,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

const mCNPJ = cnpj => {
  cnpj = cnpj.replace(/\D/g, "");
  cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2");
  cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
  cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2");
  cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2");
  return cnpj;
};

const mCPF = cpf => {
  cpf = cpf.replace(/\D/g, "");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  return cpf;
};

/**
 * @typedef {{
    items: [field] |
    [
      {
        label:  String,
        value: String|Number
      }
    ],
    multiple: boolean ,
    break: boolean ,
    maxLength: number,
    type: String ,
    noSelectionLabel: ""|"Selecione uma opção",
    format: {
      // @see https://nosir.github.io/cleave.js/
      numeral: true| false,
      numeralDecimalMark: "."|",",
      delimiter: "."|",",
      cpf: true|false,
      cnpj: true|false,
    },
    validation: {
      required: true|false
    },
    tooltip: {
      title: "",
      placement: "bottom"|"top"|"left"|"right"
    },
    adornment: "",
    allowNoSelection: true|false,
    name: String ,
    title: String ,
    disabled: true|false ,
    fieldProps: {},
    cols: {
      xs: 0|1|2|3|4|5|6|7|8|9|10|11|12 ,
      sm: 0|1|2|3|4|5|6|7|8|9|10|11|12 ,
      md: 0|1|2|3|4|5|6|7|8|9|10|11|12 ,
      lg: 0|1|2|3|4|5|6|7|8|9|10|11|12 ,
      xl: 0|1|2|3|4|5|6|7|8|9|10|11|12 ,
    },
  } field
**/

// function NumberFormatCustom(props) {
//   const { inputRef, onChange, ...other } = props;
//   console.info(props.name);
//   return (
//     <NumberFormat
//       key={"txt-number-" + other.id}
//       onValueChange={values => {
//         onChange({
//           target: {
//             name: other.name,
//             value: values.value,
//           },
//         });
//       }}
//       {...other}
//       getInputRef={inputRef}
//       thousandSeparator="."
//       decimalSeparator=","
//       decimalScale={2}
//     />
//   );
// }
// NumberFormatCustom.propTypes = {
//   inputRef: PropTypes.func.isRequired,
//   onChange: PropTypes.func.isRequired,
// };

const InputNumberComponent = props => {
  const { options: opt, inputRef, format, ...other } = props;
  return <Cleave key={"cleave-"} {...other} options={format} htmlRef={inputRef}></Cleave>;
};

/**
 * @param {{
   handleChange: (event: Event) => {}
   fields: [field]
  }} props
 */
const FormBuilder = props => {
  const classes = useStyles();
  const rows = [];
  let cols = [];
  const [state, setState] = useState({});
  // const lastState = useRef(props.state);

  // useEffect(() => {
  //   setTimeout(() => {
  //     props.fields.forEach(field => {
  //       if (field.validation && field.validation.required) {
  //         const value = props.state[field.name];
  //         if (value) {
  //           if (value instanceof Array && value.length === 0) {
  //             return;
  //           }

  //         }
  //       }
  //     }, 10);
  //   });
  // }, [props.state]);

  props.fields.forEach(field => {
    cols.push(field);

    if (field.break) {
      rows.push(cols);
      cols = [];
    }
  });

  if (cols.length > 0) {
    rows.push(cols);
  }

  const renderField = (field, i) => {
    if (field.type === FormType.SPACER) {
      return <div></div>;
    }

    let fieldStructure = <></>;
    switch (field.type) {
      case FormType.SELECT: {
        fieldStructure = (
          <FormControl variant="outlined">
            <Select
              name={field.name}
              key={"slc-" + field.name + "-" + i}
              required={field.validation && field.validation.required}
              type={field.type || FormType.TEXT}
              disabled={field.disabled}
              error={state[field.name + "Error"]}
              value={props.state[field.name]}
              displayEmpty
              onChange={e => {
                props.handleChange(e);
                state[field.name + "Error"] = false;
                setState({ ...state });
              }}
              multiple={field.multiple}
              // components={field.multiple ? components : undefined}
              {...field.fieldProps}
              renderValue={
                field.multiple
                  ? selected => (
                      <div className={classes.chips}>
                        {selected.map(value => {
                          // console.warn(value);
                          return value ? (
                            <Chip
                              key={value}
                              label={
                                value
                                  ? (field.items.filter(e => e.value === value)[0] || {}).label
                                  : ""
                              }
                              className={classes.chip}
                            />
                          ) : (
                            undefined
                          );
                        })}
                      </div>
                    )
                  : undefined
              }
              input={
                <OutlinedInput
                  name={field.name}
                  id={field.id || field.name}
                  {...field.inputProps}
                />
              }
            >
              {field.items.map((item, i) => {
                return (
                  <MenuItem key={field.name + "-" + i} value={item.value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
            {state[field.name + "Error"] && (
              <FormHelperText
                id={"component-helper-" + field.name}
                error={state[field.name + "Error"]}
              >
                Este campo é obrigatório
              </FormHelperText>
            )}
          </FormControl>
        );
        break;
      }

      case FormType.RADIO: {
        fieldStructure = (
          <>
            <RadioGroup
              aria-label={field.name}
              key={"rdg-" + field.name + "-" + i}
              name={field.name}
              value={props.state[field.name]}
              onChange={props.handleChange}
              required={field.validation && field.validation.required}
              error={state[field.name + "Error"]}
              row
              {...field.fieldProps}
            >
              {field.items.map((item, i) => {
                return (
                  <FormControlLabel
                    key={field.name + "-" + i}
                    control={<Radio color="primary" />}
                    labelPlacement="start"
                    {...item}
                  />
                );
              })}
            </RadioGroup>
            {state[field.name + "Error"] && (
              <FormHelperText
                id={"component-helper-" + field.name}
                error={state[field.name + "Error"]}
              >
                Este campo é obrigatório
              </FormHelperText>
            )}

            {field.feedback && (
              <FormHelperText id={"component-helper-" + field.name + "-feedback"} error={false}>
                {field.feedback}
              </FormHelperText>
            )}
          </>
        );
        break;
      }

      case FormType.DATE: {
        fieldStructure = (
          <>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
              <KeyboardDatePicker
                margin="normal"
                name={field.name}
                key={"datePicker-" + field.name + "-" + i}
                required={field.validation && field.validation.required}
                format="DD/MM/YYYY"
                error={state[field.name + "Error"]}
                todayLabel="Hoje"
                clearLabel={"Limpar"}
                cancelLabel={"Cancelar"}
                okLabel="Ok"
                value={props.state[field.name]}
                // defaultValue={""}
                onChange={(date, value) => {
                  state[field.name + "Error"] = false;
                  setState({ ...state });
                  props.handleChange({
                    target: { value: date, valueStr: value, name: field.name, date },
                  });
                }}
                inputVariant={"outlined"}
                KeyboardButtonProps={{
                  "aria-label": "Alterar data",
                }}
                disabled={field.disabled}
                {...field.fieldProps}
              />
            </MuiPickersUtilsProvider>
            {state[field.name + "Error"] && (
              <FormHelperText
                id={"component-helper-" + field.name}
                error={state[field.name + "Error"]}
              >
                Este campo é obrigatório
              </FormHelperText>
            )}
          </>
        );
        break;
      }

      case FormType.TIME: {
        fieldStructure = (
          <>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
              <KeyboardTimePicker
                margin="normal"
                name={field.name}
                key={"time-" + field.name + "-" + i}
                required={field.validation && field.validation.required}
                error={state[field.name + "Error"]}
                value={props.state[field.name]}
                clearable
                ampm={false}
                todayLabel="Agora"
                clearLabel={"Limpar"}
                cancelLabel={"Cancelar"}
                okLabel="Ok"
                onChange={(date, value) => {
                  state[field.name + "Error"] = false;
                  setState({ ...state });
                  props.handleChange({
                    target: { value: date, valueStr: value, name: field.name, date },
                  });
                }}
                inputVariant={"outlined"}
                KeyboardButtonProps={{
                  "aria-label": "Alterar data",
                }}
                disabled={field.disabled}
                {...field.fieldProps}
              />
            </MuiPickersUtilsProvider>
            {state[field.name + "Error"] && (
              <FormHelperText
                id={"component-helper-" + field.name}
                error={state[field.name + "Error"]}
              >
                Este campo é obrigatório
              </FormHelperText>
            )}
          </>
        );
        break;
      }

      case FormType.NUMBER: {
        fieldStructure = (
          <>
            <TextField
              variant={"outlined"}
              helperText={field.feedbackInvalid || field.feedback}
              error={state[field.name + "Error"]}
              key={"txt-number-" + field.name + "-" + i}
              type={"phone"}
              InputProps={{
                startAdornment: field.adornment && (
                  <InputAdornment position="start">{field.adornment}</InputAdornment>
                ),
                inputComponent: InputNumberComponent,
                inputProps: {
                  format: field.format,
                },
              }}
              name={field.name}
              id={field.name}
              required={field.validation && field.validation.required}
              disabled={field.disabled}
              value={props.state[field.name]}
              onChange={e => {
                state[field.name + "Error"] = false;
                setState({ ...state });
                if (props.handleChange) {
                  props.handleChange.call(e.target, e);
                }
              }}
              {...field.fieldProps}
            />
            {state[field.name + "Error"] && (
              <FormHelperText
                id={"component-helper-" + field.name}
                error={state[field.name + "Error"]}
              >
                Este campo é obrigatório
              </FormHelperText>
            )}
          </>
        );

        break;
      }

      case FormType.BUTTON: {
        fieldStructure = (
          <Button {...field.fieldProps}>
            {field.icon && <field.icon style={{ marginRight: 5 }} />}
            {field.title}
          </Button>
        );
        break;
      }

      case FormType.FILE: {
        fieldStructure = (
          <ImageUpload
            {...field.fieldProps}
            name={field.name}
            id={field.id}
            handleChange={({ data, name }) => {
              props.handleChange({ target: { name, value: data }, name });
            }}
          ></ImageUpload>
        );
        break;
      }

      case FormType.CHECK: {
        fieldStructure = (
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                name={field.name}
                checked={props.state[field.name]}
                value={props.state[field.name]}
                onChange={e => {
                  props.handleChange(e, field.name);
                }}
              />
            }
            label={field.title}
          />
        );
        break;
      }

      case FormType.PASSWORD: {
        const handleClickShowPassword = name => {
          return () =>
            setState(s => ({ ...s, ["showPassword" + name]: !s["showPassword" + name] }));
        };

        const handleMouseDownPassword = event => {
          event.preventDefault();
        };

        fieldStructure = (
          <>
            <FormControl variant="filled">
              <OutlinedInput
                type={state["showPassword" + field.name] ? "text" : "password"}
                value={props.state[field.name]}
                name={field.name}
                id={field.name}
                helperText={field.feedbackInvalid || field.feedback}
                error={state[field.name + "Error"]}
                key={"txt-password-" + field.name}
                required={field.validation && field.validation.required}
                disabled={field.disabled}
                onChange={e => {
                  state[field.name + "Error"] = false;
                  setState({ ...state });
                  if (props.handleChange) {
                    props.handleChange.call(e.target, e);
                  }
                }}
                inputProps={field.fieldProps}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword(field.name)}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {state["showPassword" + field.name] ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {state[field.name + "Error"] && (
              <FormHelperText
                id={"component-helper-" + field.name}
                error={state[field.name + "Error"]}
              >
                Este campo é obrigatório
              </FormHelperText>
            )}
          </>
        );
        break;
      }

      default: {
        let maxLength = field.format && field.format.cpf ? 14 : undefined;
        if (!maxLength) {
          maxLength = field.format && field.format.cnpj ? 18 : undefined;
        }

        if (!maxLength) {
          maxLength = field.maxLength;
        }
        // console.info(maxLength, field.name);
        fieldStructure = (
          <>
            <TextField
              variant={"outlined"}
              helperText={field.feedbackInvalid || field.feedback}
              name={field.name}
              required={field.validation && field.validation.required}
              isInvalid={state[field.name + "Error"]}
              type={field.type}
              disabled={field.disabled}
              value={props.state[field.name]}
              maxLength={maxLength}
              onChange={e => {
                state[field.name + "Error"] = false;
                setState({ ...state });
                if (field.format && field.format.cpf) {
                  e.target.value = mCPF(e.target.value);
                } else if (field.format && field.format.cnpj) {
                  e.target.value = mCNPJ(e.target.value);
                }
                props.handleChange(e);
              }}
              {...field.fieldProps}
            />
            {state[field.name + "Error"] && (
              <FormHelperText
                id={"component-helper-" + field.name}
                error={state[field.name + "Error"]}
              >
                Este campo é obrigatório
              </FormHelperText>
            )}
          </>
        );
      }
    }

    return (
      <>
        <Form.Label
          style={
            field.type === FormType.BUTTON || field.type === FormType.CHECK
              ? { display: "flex", height: 24 }
              : undefined
          }
        >
          {field.type === FormType.BUTTON || field.type === FormType.CHECK ? (
            <span style={{ height: "100%", display: "inline-block" }}></span>
          ) : (
            field.title
          )}
          {field.validation && field.validation.required && (
            <Tooltip title={"Este campo é obrigatório!"} placement={"bottom"}>
              <span className={"validation-icon"}>*</span>
            </Tooltip>
          )}
        </Form.Label>
        {field.tooltip ? (
          <Tooltip title={field.tooltip.title} placement={field.tooltip.placement || "bottom"}>
            {fieldStructure}
          </Tooltip>
        ) : (
          <>{fieldStructure}</>
        )}
        {field.feedback && <Form.Control.Feedback>{field.feedbackValid}</Form.Control.Feedback>}
        {field.feedbackInvalid && (
          <Form.Control.Feedback type="invalid">{field.feedbackInvalid}</Form.Control.Feedback>
        )}
      </>
    );
  };

  /**
   *
   * @param {field} field
   * @param {Number} i
   */
  const renderFields = (field, i) => {
    field.fieldProps = field.fieldProps || field.fieldProps;
    field.allowNoSelection = field.allowNoSelection === undefined ? true : field.allowNoSelection;
    if (field.type !== FormType.RADIO && field.type !== FormType.PANEL) {
      if (field.allowNoSelection && field.items) {
        let hasNoSelect = false;

        field.items.every(it => {
          hasNoSelect = it.value === "";
          return !hasNoSelect;
        });

        if (!hasNoSelect) {
          field.items = [
            { value: "", label: field.noSelectionLabel || "Selecione uma opção" },
            ...(field.items || []),
          ];
        }
      }
    }

    return (
      <Form.Group
        key={"col-" + field.name + "-" + i}
        as={Col}
        {...field.cols}
        controlId={"validation-" + field.name}
      >
        {field.type === FormType.ENDERECO ? (
          <Endereco
            state={props.state}
            handleChange={props.handleChange}
            prefix={field.prefix}
            {...field.fieldProps}
          />
        ) : (
          renderField(field, i)
        )}
      </Form.Group>
    );
  };

  const onSave = e => {
    let valid = true;

    const validField = field => {
      if (field.validation && field.validation.required) {
        let value = props.state[field.name];
        if (!value) {
          state[field.name + "Error"] = true;
          valid = false;
        } else if (value instanceof Array) {
          if (value.length === 0) {
            state[field.name + "Error"] = true;
            valid = false;
          }
        }
      }
    };
    props.fields.forEach(field => {
      if (field.type === FormType.PANEL) {
        field.items.forEach(subfield => {
          validField(subfield);
        });
      } else {
        validField(field);
      }
    });

    setState({ ...state });

    if (!valid) {
      Swal.fire({
        text: "Verifique o formulário e tente novamente!",
        // toast: true,
        type: "warning",
        // position: "top-end",
      });
    }

    if (props.onSaveButtonClick && valid) {
      props.onSaveButtonClick(e);
    }
  };

  const SaveButton = ({ bottom = false }) => (
    <>
      <Button
        id="btnSubmit"
        variant="contained"
        type={props.saveButtonType}
        onClick={onSave}
        size="large"
        color="primary"
        style={{
          marginBottom: bottom ? undefined : 10,
          marginTop: bottom ? 30 : undefined,
          ...props.saveButtonStyle,
        }}
      >
        <props.saveButtonIcon style={{ marginRight: 5 }} /> {props.saveButtonText || "Salvar"}
      </Button>
    </>
  );

  SaveButton.propTypes = {
    bottom: PropTypes.bool,
  };

  const renderForm = rows => {
    return (
      <>
        {props.saveButtonOnTop && props.saveButton && <SaveButton />}
        {rows.map((cols, i) => {
          return (
            <Form.Row key={"root-row-" + i}>
              {cols.map((field, i) => {
                if (field.type === FormType.PANEL) {
                  let innerRows = [];
                  let innerCols = [];

                  field.items.forEach(innerField => {
                    innerCols.push(innerField);

                    if (innerField.break) {
                      innerRows.push(innerCols);
                      innerCols = [];
                    }
                  });

                  if (cols.length > 0) {
                    innerRows.push(innerCols);
                  }

                  return (
                    <Col {...field.cols} key={"root-col-panel" + i}>
                      <Typography
                        variant="h6"
                        component="h6"
                        style={{ marginBottom: 10, fontWeight: 400 }}
                      >
                        {field.title}
                      </Typography>
                      <Paper className={classes.panel} {...field.fieldProps}>
                        {innerRows.map((innerCol, j) => {
                          return (
                            <Form.Row key={"inner-row-" + j}>
                              {innerCol.map((innerField, j) => {
                                return renderFields(innerField, j + "-" + i);
                              })}
                            </Form.Row>
                          );
                        })}
                      </Paper>
                    </Col>
                  );
                } else {
                  return renderFields(field, i);
                }
              })}
            </Form.Row>
          );
        })}
        {props.children}

        {!props.saveButtonOnTop && props.saveButton && <SaveButton bottom />}
      </>
    );
  };
  const RootComponent = props.rootComponent;
  return (
    <RootComponent validated={props.validated}>
      {props.rootPaper ? (
        <Paper className={classes.root}>{renderForm(rows)}</Paper>
      ) : (
        renderForm(rows)
      )}
    </RootComponent>
  );
};

FormBuilder.propTypes = {
  state: PropTypes.object,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      items: PropTypes.arrayOf([
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired,
        }),
      ]),
      multiple: PropTypes.bool,
      break: PropTypes.bool,
      type: PropTypes.string.isRequired,
      noSelectionLabel: PropTypes.oneOf(["", "Selecione uma opção"]),
      format: PropTypes.shape({
        numeral: PropTypes.bool,
        numeralDecimalMark: PropTypes.oneOf([".", ","]),
        delimiter: PropTypes.oneOf([".", ","]),
        cpf: PropTypes.bool,
        cnpj: PropTypes.bool,
      }),
      validation: PropTypes.shape({
        required: PropTypes.bool,
      }),
      tooltip: PropTypes.shape({
        title: PropTypes.string,
        placement: PropTypes.oneOf(["bottom", "top", "left", "right"]),
      }),
      adornment: PropTypes.string,
      allowNoSelection: PropTypes.bool,
      name: PropTypes.string.isRequired,
      title: PropTypes.string,
      disabled: PropTypes.bool,
      fieldProps: PropTypes.object,
      cols: PropTypes.shape({
        xs: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]).isRequired,
        sm: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
        md: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
        lg: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
        xl: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
      }).isRequired,
    }),
  ),
  handleChange: PropTypes.func.isRequired,
  rootPaper: PropTypes.bool,
  rootComponent: PropTypes.node,
  saveButtonText: PropTypes.string,
  saveButtonStyle: PropTypes.object,
  onSaveButtonClick: PropTypes.func.isRequired,
  saveButtonType: PropTypes.string,
  saveButtonOnTop: PropTypes.bool,
  children: PropTypes.node,
  validated: PropTypes.bool,
};

FormBuilder.defaultProps = {
  rootPaper: true,
  saveButtonOnTop: false,
  saveButtonIcon: Save,
  saveButtonType: "submit",
  rootComponent: Form,
  saveButton: true,
};

export const FormType = {
  SELECT: "select",
  TEXT: "text",
  NUMBER: "number",
  FILE: "file",
  RADIO: "radio",
  DATE: "date",
  TIME: "time",
  SPACER: "",
  PANEL: "panel",
  ENDERECO: "endereco",
  BUTTON: "button",
  CHECK: "checkbox",
  PASSWORD: "password",
};
export default FormBuilder;
