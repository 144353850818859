import React, { useEffect, useState, useCallback } from "react";
import Page from "../../components/Page";
import MiniCard from "../../components/MiniCard";
import Row from "react-bootstrap/Row";
import "./style.scss";
import Controller from "./controller";
import FormControl from "@material-ui/core/FormControl";
import Col from "react-bootstrap/Col";
import { Select, MenuItem, OutlinedInput, Typography } from "@material-ui/core";

import { ReactComponent as iconStack } from "../../assets/stacks.svg";

import TrendingUp from "@material-ui/icons/TrendingUp";
import TrendingDown from "@material-ui/icons/TrendingDown";
import Map from "@material-ui/icons/Map";
import MultilineChart from "@material-ui/icons/MultilineChart";
import Archive from "@material-ui/icons/Archive";

import iconMap from "../../assets/icon-area-total.svg";
import iconAndamento from "../../assets/icon-col-andamento.svg";
import iconVolOp from "../../assets/icon-vol-operacoes.svg";
import iconRisk from "../../assets/icon-risk.svg";
import iconVolOpTrade from "../../assets/icon-vol-trade.svg";
import { ReactComponent as iconVolProp } from "../../assets/icon-vol-proprio.svg";

import ValorMilhoes from "./components/ValorMilhoes";
import VolumeTon from "./components/VolumeTon";
import VolumeSacas from "./components/VolumeSacas";
import Operacoes from "./components/Operacoes";
import Estatisticas from "./components/Estatisticas";
import AreaColhidaCpr from "./components/AreaColhidaCpr";
import AnexoController from "../CadastroCpr/AnexoController";
import { default as CprController } from "../CadastroCpr/controller";
import { default as OperacoesController } from "../Operacoes/controller";
import AreaTotalXAreaEfetiva from "./components/AreaTotalXAreaEfetiva";
import Swal from "sweetalert2";

const icons = {
  TrendingUp,
  TrendingDown,
  Map,
  MultilineChart,
  Archive,

  iconStack,
  iconMap,
  iconAndamento,
  iconVolOp,
  iconRisk,
  iconVolOpTrade,
  iconVolProp,
};

const controller = new Controller();
const operacoesController = new OperacoesController();

const Dashboard = () => {
  const [miniCads, setMiniCards] = useState([]); // initial state
  const [filtro, setFiltro] = useState(99); // initial state
  const [valorMilhoes, setValorMilhoes] = useState({}); // initial state
  const [volumeSacas, setVolumeSacas] = useState({});
  const [volumeTon, setVolumeTon] = useState([0, 0, 0, 0, 0]); // max 4 data
  const [loading, setLoading] = useState(false);
  const [areaEfetiva, setAreaEfetiva] = useState(0);
  const [areaTotal, setAreaTotal] = useState(0);
  const [filtroSafra, setFiltroSafra] = useState(99);
  const [statisticas, setStatisticas] = useState({ um: [], tres: [], sete: [], quinze: [] });
  const [statisticasFilter, setStatisticasFilter] = useState("um");
  const [dadosColheita, setDadosColheita] = useState({ toneladasColhidas: {} });
  const [mesVolumeTon, setMesVolumeTon] = useState(new Date().getMonth());

  const [operacoes, setOperacoes] = useState({
    cadastrada: 0,
    vistoriaAgendada: 0,
    vistoriando: 0,
    aguardandoColheita: 0,

    colhendo: 0,
    colheitaSupervisionada: 0,
    colheitaFinalizada: 0,
    liquidada: 0,

    total: 0,
    laudosenviados: 0,
  });

  const [areaColhidaCpr, setAreaColhidaCpr] = useState({
    total: 1500,
    colhido: 900,
  }); // max 4 data

  const getOperacoesAnexosFromVisita = async (numerovisita, filtroSafra) => {
    const codigoCprUnica = new Set();
    let listaCpr = await new CprController().listar(e => {
      return e.safra === filtroSafra || filtroSafra === 99;
    });

    const anexos = await new AnexoController().listar(e => {
      const existeNasCprs = listaCpr.filter(c => c.codigo === Number(e.cprCodigo))[0];
      if (!existeNasCprs) {
        return false;
      }
      if (numerovisita && numerovisita !== 99) {
        if (e.numeroVisita === numerovisita) {
          codigoCprUnica.add(e.cprCodigo);
          return true;
        }
      } else {
        codigoCprUnica.add(e.cprCodigo);
        return true;
      }
      return false;
    }, true);

    listaCpr = listaCpr.filter(e => {
      return codigoCprUnica.has(e.codigo.toString()) || numerovisita === 99;
    });

    return [listaCpr, anexos];
  };

  const filterDataFromVisita = useCallback(async (filtro, filtroSafra) => {
    setLoading(true);
    const [listaCpr, listaAnexos] = await getOperacoesAnexosFromVisita(filtro, filtroSafra);

    let af = 0;
    listaAnexos.forEach(e => {
      if (e.areaEfetiva) {
        af += Number(e.areaEfetiva);
      }
    });

    let at = 0;
    listaCpr.forEach(cpr => {
      at += Number(cpr.areatotal.replace(/[.]/g, "").replace(",", "."));
    });

    setAreaEfetiva(af);
    setAreaTotal(at);

    Promise.all([
      controller.getMiniCards(listaCpr, listaAnexos),
      controller.getValorMilhoes(listaCpr),
      controller.getVolumeSacas(listaCpr),
      controller.getAreaColhidaCPR(listaCpr),
      controller.getOperacoes(listaCpr, listaAnexos),
    ]).then(([minicards, valorMilhoes, volumesacas, areColhidaCpr, operac]) => {
      setMiniCards(minicards);
      setValorMilhoes(valorMilhoes);
      setVolumeSacas(volumesacas);
      setAreaColhidaCpr(areColhidaCpr);
      setOperacoes(operac);
      setLoading(false);
    });

    Swal.fire({
      toast: true,
      text: "Carregando informações de estatisticas...",
      position: "bottom-start",
      allowOutsideClick: false,
      allowEnterKey: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    operacoesController
      .getDataStatistics(null, filtroSafra, true)
      .then(data => {
        setStatisticas(data);
        Swal.update({
          text: "Carregando informações das cargas...",
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });

        operacoesController
          .getOperacoes(null, filtroSafra, true)
          .then(async data => {
            const mCards = await controller.getMiniCards(listaCpr, listaAnexos, data);
            setMiniCards(mCards);
            setDadosColheita(data);
            setVolumeTon(data.toneladasColhidas[mesVolumeTon]);
            controller.getValorMilhoes(listaCpr, data).then(valorMilhoes => {
              setValorMilhoes(valorMilhoes);
            });
          })
          .finally(() => {
            Swal.close();
            Swal.hideLoading();
          });
      })
      .catch(() => {
        Swal.hideLoading();
        Swal.close();
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // nao usar await no didmount pode ocorrer travamento na pagina.
    filterDataFromVisita(filtro, filtroSafra);
  }, [filtro, filtroSafra, filterDataFromVisita]); //didmount/update

  /**
   * @param {{ target: { value: React.SetStateAction<number>; }; }} event
   */
  function handleChange(event) {
    setFiltro(event.target.value);
  }

  function handleChangeSafra(event) {
    setFiltroSafra(event.target.value);
  }

  return (
    <Page
      loading={loading}
      titlePage="Visao Geral"
      containerTitle={
        <>
          <div className="container-filtro-safra">
            <Typography
              variant={"subtitle1"}
              htmlFor="filtroVisita"
              style={{ marginLeft: 10, display: "flex", alignItems: "center" }}
            >
              Safra:{" "}
            </Typography>
            <FormControl variant="outlined" style={{ marginLeft: 5 }}>
              <Select
                id="filtroSafra"
                value={filtroSafra}
                onChange={handleChangeSafra}
                input={<OutlinedInput name="age" id="outlined-age-simple" />}
              >
                <MenuItem value={99}>Geral</MenuItem>
                <MenuItem value={2018}>2018/2019 - safra</MenuItem>
                <MenuItem value={2019}>2019/2020 - safra</MenuItem>
                <MenuItem value={2020}>2020/2020 - safra</MenuItem>
                <MenuItem value={20201}>2020/2021 - safra</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="container-filtro-safra">
            <Typography
              variant={"subtitle1"}
              htmlFor="filtroVisita"
              style={{ marginLeft: 10, display: "flex", alignItems: "center" }}
            >
              Visita:{" "}
            </Typography>
            <FormControl variant="outlined" style={{ marginLeft: 5 }}>
              <Select
                id="filtroVisita"
                value={filtro}
                onChange={handleChange}
                input={<OutlinedInput name="age" id="outlined-age-simple" />}
              >
                <MenuItem value={99}>Geral</MenuItem>
                <MenuItem value={1}>1ª visita</MenuItem>
                <MenuItem value={2}>2ª visita</MenuItem>
                <MenuItem value={3}>3ª visita</MenuItem>
                <MenuItem value={4}>4ª visita</MenuItem>
                <MenuItem value={5}>5ª visita</MenuItem>
              </Select>
            </FormControl>
          </div>
        </>
      }
    >
      <Row className="row-container-mini-cards">
        {miniCads.map((e, i) => {
          const icon = icons[e.icon];
          return <MiniCard key={i} {...e} icon={icon} />;
        })}
      </Row>

      <Row>
        <Col xl={3} lg={12}>
          <Row className="row-valor-milhoes">
            <ValorMilhoes {...valorMilhoes} />
          </Row>
        </Col>

        <Col xl={9} lg={12}>
          <Row>
            <VolumeTon
              ton={volumeTon}
              handleChange={month => {
                const volumeDoMes = dadosColheita.toneladasColhidas[month];
                setMesVolumeTon(month);
                setVolumeTon(volumeDoMes || [0, 0, 0, 0, 0]);
              }}
            />
            <AreaTotalXAreaEfetiva areaEfetiva={areaEfetiva} areaTotal={areaTotal} />
          </Row>
        </Col>
      </Row>
      <Row>
        <Operacoes
          titleChart={"Operações I"}
          data={{
            itens: [
              {
                text: "Cadastradas",
                quantidade: operacoes.cadastrada,
              },
              {
                text: "Vistoria agendada",
                quantidade: operacoes.vistoriaAgendada,
              },
              {
                text: "Vistoriando",
                quantidade: operacoes.vistoriando,
              },
              {
                text: "Aguardando colheita",
                quantidade: operacoes.aguardandoColheita,
              },
            ],
            total: operacoes.total,
            laudos: operacoes.laudosenviados,
          }}
        />
        <Operacoes
          titleChart={"Operações II"}
          data={{
            itens: [
              {
                text: "Colheita em andamento",
                quantidade: operacoes.colhendo,
              },
              {
                text: "Colheita supervisionada",
                quantidade: operacoes.colheitaSupervisionada,
              },
              {
                text: "Colheita finalizada",
                quantidade: operacoes.colheitaFinalizada,
              },
              {
                text: "Liquidada",
                quantidade: operacoes.liquidada,
              },
            ],
          }}
        />
      </Row>
      <Row>
        <Estatisticas
          style={{ padding: 4 }}
          onChangeFilter={filter => {
            setStatisticasFilter(filter);
          }}
          data={{
            labels: statisticas[statisticasFilter].map(e => e.dia),
            datasets: [
              {
                label: "Volume entregue na tradding",
                borderColor: "#a3a0fb",
                backgroundColor: ["#a3a0fb25"],
                data: statisticas[statisticasFilter].map(e => e.volumeTrading),
                fill: "start",
              },
              {
                label: "Volume das operações",
                borderColor: "#7fe2ff",
                backgroundColor: ["#7fe2ff55"],
                data: statisticas[statisticasFilter].map(e => e.volumeTotal),
                fill: "start",
              },
            ],
          }}
        />
        <Col xl={4} lg={12} style={{ padding: 0 }}>
          <AreaColhidaCpr colhido={dadosColheita.hectaresColhidos} total={areaColhidaCpr.total} />
          <VolumeSacas
            {...volumeSacas}
            atingido={dadosColheita.volumeDeOperacoesSilo}
            restante={volumeSacas.total - dadosColheita.volumeDeOperacoesSilo}
          />
        </Col>
      </Row>
    </Page>
  );
};

export default Dashboard;
