import ServiceController from "../../components/ServiceController";

export default class AnexoService extends ServiceController {
  constructor() {
    super("anexo");
  }

  async listar(cache, cprCodigo) {
    // debugger;
    return this.fetchData(this.tableName, {
      url: "anexos" + (cprCodigo ? "?cprCodigo=" + cprCodigo : ""),
      type: "get",
      cache,
      data: { cprCodigo },
    });
  }

  async salvar(data) {
    return this.fetchData(this.tableName, {
      url: "anexos/",
      type: "post",
      data,
      cache: false,
    });
  }

  async remover(item) {
    return this.fetchData(this.tableName, {
      url: "anexos/id?id=" + item.id,
      type: "delete",
      cache: false,
    });
  }
}
