import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./style.scss";
import Nav from "react-bootstrap/Nav";
import { default as logo } from "../../../../assets/logo-icon.svg";
import { Home, Info, Settings } from "@material-ui/icons";

const menus = [
  {
    href: "monitor/monitoramentos",
    icon: Home,
    nome: "Início",
    role: [2],
    opened: true,
  },
  {
    href: "monitor/centralajuda",
    icon: Info,
    nome: "Central de ajuda",
  },
  {
    href: "monitor/configurações",
    icon: Settings,
    nome: "Configurações",
  },
];

const Menu = props => {
  const [urlLogo, setUrlLogo] = useState("./logo-cliente.png");
  return (
    <>
      <div
        className={`mon-menu-overlay ` + (props.menuOpen ? `opened ` : ``)}
        onClick={() => props.setMenuOpen(!props.menuOpen)}
      ></div>
      <div className={`mon-container-menu ` + (props.menuOpen ? `opened ` : ``)}>
        <div className="container-logo">
          <img
            src={urlLogo}
            alt="Logo"
            onError={e => {
              //console.warn("Falha ao carregar logo, buscando alternativa...");
              if (urlLogo.includes("cliente.png")) {
                setUrlLogo(urlLogo.replace("png", "svg"));
              } else if (urlLogo.includes("cliente.svg")) {
                setUrlLogo(logo);
              }
            }}
          />
          <span className="nome-empresa">INSPECTO AGRI</span>
        </div>

        <Nav className="mr-auto">
          {menus.map((menu, i) => {
            if (!menu.role) {
              menu.role = [0, 1, 2]; // user/admin/monitor
            }

            if (!menu.role.includes(props.usuarioLogado.role)) {
              return null; //<></>;
            }

            return (
              <Nav.Link
                key={i}
                href={menu.opened || menu.preview ? "#/" + menu.href : undefined}
                className={window.location.href.endsWith("#/" + menu.href) ? "active" : ""}
              >
                {menu.icon && <menu.icon />}
                {menu.nome}{" "}
                {!menu.opened && (
                  <i
                    style={{
                      marginLeft: 5,
                      fontSize: 10,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    (em breve)
                  </i>
                )}
              </Nav.Link>
            );
          })}
          {props.menus}
        </Nav>
        <span className="version">{props.version}</span>
      </div>
    </>
  );
};

Menu.propTypes = {
  children: PropTypes.element,
  menus: PropTypes.arrayOf(PropTypes.element),
  version: PropTypes.string,
};

const mapStateToProps = store => ({
  usuarioLogado: store.sessionState.usuarioLogado,
  version: store.sessionState.version,
});

export default connect(mapStateToProps)(Menu);
