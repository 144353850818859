import React from "react";
import { moment } from "../../../utils/TimeZone";
import { Item, Title, Description } from "./ListUtils";
import { Paper, Grid, Box, Typography, Button } from "@material-ui/core";
import { Sync } from "@material-ui/icons";

export default function ListWithItItems(props) {
  return (
    <>
      {props.showSync && (
        <Box p={1} style={{ textAlign: "center" }}>
          <Typography variant="body2">Seus dados não estão sincronizados</Typography>
          <Button onClick={props.conClickForceLoad} variant="outlined" color={"primary"}>
            Clique aqui para sincronizar <Sync />
          </Button>
        </Box>
      )}
      {props.items.length > 0 ? (
        props.items.map((item, i) => (
          <Paper
            style={{
              cursor: "pointer",
              padding: 10,
              marginBottom: 5,
              ...props.style,
            }}
            key={"card-monitoramento-tipo-" + props.tipo + "-" + i}
            className={props.className}
            onClick={e => {
              props.onClick.call(e.target, item, props.tipo);
            }}
          >
            {props.tipo === 0 ? (
              <Grid xs={12} container direction="row" spacing={1}>
                <Item cols={{ xs: 6 }}>
                  <Title>Data/Hora:</Title>
                  <Description>
                    {moment(item.dataHora).format("DD/MM/YYYY")} /{" "}
                    {moment(item.horaInicial).format("HH:mm")}
                  </Description>
                </Item>
                <Item cols={{ xs: 6 }}>
                  <Title>Volume em Kg:</Title>
                  <Description>{item.volume}</Description>
                </Item>
                <Item cols={{ xs: 6 }}>
                  <Title>Fazenda:</Title>
                  <Description>
                    {props.itemSelecionado.fazenda && props.itemSelecionado.fazenda.nome}
                  </Description>
                </Item>
                <Item cols={{ xs: 6 }}>
                  <Title>Talhão colhido:</Title>
                  <Description>{item.talhaoColhido}</Description>
                </Item>
                <Item cols={{ xs: 6 }}>
                  <Title>Houve colheita:</Title>
                  <Description>{item.naoHouveColheita ? "Não" : "Sim"}</Description>
                </Item>
                <Item cols={{ xs: 6 }}>
                  <Title>Área Colhida:</Title>
                  <Description>{item.areaColhida}</Description>
                </Item>
                <Item cols={{ xs: 12 }}>
                  <Title>Observação:</Title>
                  <Description>{item.observacoes}</Description>
                </Item>
                {item.sync && (
                  <Item cols={{ xs: 6 }}>
                    <Title style={{ color: "red" }}>Ainda não sincronizado</Title>
                  </Item>
                )}
              </Grid>
            ) : (
              <>
                <Grid xs={12} container direction="row" spacing={1}>
                  <Item cols={{ xs: 6 }}>
                    <Title>Data:</Title>
                    <Description>{moment(item.dataHora).format("DD/MM/YYYY")}</Description>
                  </Item>

                  <Item cols={{ xs: 6 }}>
                    <Title>Horário Carregamento:</Title>
                    <Description>{moment(item.horaSaida).format("HH:mm")}</Description>
                  </Item>

                  <Item cols={{ xs: 6 }}>
                    <Title>Fazenda:</Title>
                    <Description>
                      {props.itemSelecionado.fazenda && props.itemSelecionado.fazenda.nome}
                    </Description>
                  </Item>

                  <Item cols={{ xs: 6 }}>
                    <Title>Peso estimado:</Title>
                    <Description>{item.pesoKg}</Description>
                  </Item>

                  <Item cols={{ xs: 6 }}>
                    <Title>Placa Dianteira:</Title>
                    <Description>{item.placaCarreta}</Description>
                  </Item>

                  <Item cols={{ xs: 6 }}>
                    <Title>Placa Traseira:</Title>
                    <Description>{item.placaCavalo}</Description>
                  </Item>

                  <Item cols={{ xs: 6 }}>
                    <Title>Motorista:</Title>
                    <Description>{item.condutor}</Description>
                  </Item>

                  <Item cols={{ xs: 6 }}>
                    <Title>Local Destino:</Title>
                    <Description>{item.localEntrega}</Description>
                  </Item>

                  {item.sync && (
                    <Item cols={{ xs: 6 }}>
                      <Title style={{ color: "red" }}>Ainda não sincronizado</Title>
                    </Item>
                  )}
                </Grid>
              </>
            )}
          </Paper>
        ))
      ) : (
        <Paper
          style={{ cursor: "pointer", padding: 10, marginTop: 5, marginBottom: 5, ...props.style }}
          className={props.className}
        >
          Nenhum registro encontrado
        </Paper>
      )}
    </>
  );
}
