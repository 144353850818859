export default [
  {
    value: 1,
    label: "Soja",
  },
  {
    value: 2,
    label: "Milho",
  },
  {
    value: 3,
    label: "Feijão",
  },
  {
    value: 4,
    label: "Sorgo",
  },
  {
    value: 5,
    label: "Café",
  },
  {
    value: 6,
    label: "Algodão",
  },
];
