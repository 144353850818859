import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { Store } from "./redux/store";
import Routes from "./Routers";
import "../node_modules/bootstrap/scss/bootstrap.scss";
import Swal from "sweetalert2";

ReactDOM.render(
  <Provider store={Store}>
    <HashRouter>
      <Routes />
    </HashRouter>
  </Provider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: async registration => {
    try {
      serviceWorker.unregister();
      await Swal.fire({
        title: "Nova atualização encontrada!",
        html:
          "A plataforma será atualizada automaticamente em <b class='timer-swall'>" +
          Swal.getTimerLeft() +
          "</b>s <br> ou...",
        type: "info",
        allowOutsideClick: false,
        allowEscapeKey: false,
        timer: 10000,
        confirmButtonText: "Clique aqui para atualizar agora",
        onBeforeOpen: () => {
          window.timerInterval = setInterval(() => {
            const content = Swal.getContent();
            if (content) {
              const b = content.querySelector(".timer-swall");
              if (b) {
                b.textContent = Swal.getTimerLeft()
                  .toString()
                  .split("")
                  .map((e, i) => (i === 0 ? e + "." : e))
                  .join("");
              }
            }
          }, 100);
        },
        onClose: () => {
          clearInterval(window.timerInterval);
        },
      });

      window.location.reload();

      console.info("A plataforma será atualizada quando todas as suas abas forem fechadas!");
    } catch (e) {
      console.error("Falha ao mostrar mensagem de atualização");
    }
  },
  // onUpdate: async registration => {
  //   try {
  //     if (registration)
  //     serviceWorker.unregister();
  //     window.location.reload();
  //     console.info("A plataforma será atualizada quando todas as suas abas forem fechadas!");
  //   } catch (e) {
  //     console.error("Falha ao mostrar mensagem de atualização");
  //   }
  // },
  onSuccess: async registration => {
    try {
      await Swal.fire({
        title: "YUuuupii!",
        text: "Versão da plataforma atualizada com sucesso!",
        type: "success",
        allowOutsideClick: false,
        confirmButtonText: "Ok",
        position: "top-right",
        toast: true,
        timer: 5000,
      });
      console.info("Versão da plataforma atualizada com sucesso!");
    } catch (e) {
      console.error("Falha ao mostrar mensagem de atualização");
    }
  },
});
