import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import App from "./App";
// import Login from "./pages/Login";
import Loading from "./components/Loading";
import ListaAcompanhamentos from "./pages/Monitor/Acompanhamento/list";
// import OperacoesAggregation from "./pages/Operacoes/aggregation";

const NotFound = lazy(() => import("./pages/NotFound"));
const Operacoes = lazy(() => import("./pages/Operacoes"));
const CadastroEmitente = lazy(() => import("./pages/CadastroEmitente"));
const ListCadastroCpr = lazy(() => import("./pages/CadastroCpr/list"));
const ListCadastroEmitente = lazy(() => import("./pages/CadastroEmitente/list"));
const Nomeacao = lazy(() => import("./pages/Nomeacao"));
const Relatorio = lazy(() => import("./pages/Relatorio"));
const ListPessoas = lazy(() => import("./pages/Pessoas/list"));
const FormPessoas = lazy(() => import("./pages/Pessoas"));
const ListAutorizacao = lazy(() => import("./pages/Autorizacao/list"));
const FormAutorizacao = lazy(() => import("./pages/Autorizacao"));
const ListAtribuicao = lazy(() => import("./pages/Atribuicao/list"));
const Atribuicao = lazy(() => import("./pages/Atribuicao/"));
const Monitor = lazy(() => import("./pages/Monitor"));

const Login = lazy(() => import("./pages/Login"));
const CadastroCpr = lazy(() => import("./pages/CadastroCpr"));
const relMonitoramentoPDF = lazy(() =>
  import("./pages/Relatorio/RelatorioMonitoramento/relatorio"),
);

const Kanban = lazy(() => import("./pages/Kanban"));

const Routes = () => {
  return (
    <div>
      <Suspense
        fallback={
          <>
            <Loading loading={true} />
          </>
        }
      >
        <Switch>
          <Route exact path="/" component={App} />
          <Route path="/login" component={Login} />
          <Route path="/operacoes" component={Operacoes} />
          <Route path="/relatorioOperacoes" component={relMonitoramentoPDF} />

          <Route path="/cadastro/form" component={CadastroCpr} />
          <Route path="/cadastro/lista" component={ListCadastroCpr} />

          <Route path="/emitente/form" component={CadastroEmitente} />
          <Route path="/emitente/lista" component={ListCadastroEmitente} />
          <Route path="/emitente" component={CadastroEmitente} />
          <Route path="/nomeacao" component={Nomeacao} />
          <Route path="/relatorio" component={Relatorio} />
          <Route path="/pessoas/list" component={ListPessoas} />
          <Route path="/pessoas/form" component={FormPessoas} />

          <Route path="/atribuicao/lista" component={ListAtribuicao} />
          <Route path="/atribuicao/form" component={Atribuicao} />

          <Route path="/autorizacao/list" component={ListAutorizacao} />
          <Route path="/autorizacao/form" component={FormAutorizacao} />

          <Route path="/monitor/monitoramentos" component={Monitor} />
          <Route path="/monitor/acompanhamento/lista" component={ListaAcompanhamentos} />

          <Route path="/kanban" component={Kanban} />

          <Route exact path="*" component={NotFound} />
        </Switch>
      </Suspense>
    </div>
  );
};

export default Routes;
