import BaseController from "../../../components/BaseController";
import AcompanhamentoService from "./service";
import { FormType } from "../../../components/FormBuilder";
import Swal from "sweetalert2";

export default class AcompanhamentoController extends BaseController {
  constructor() {
    super("acompanhamento");
    this.service = new AcompanhamentoService();
  }

  async total(usuarioLogado) {
    let lista = await this.listar(usuarioLogado.id || usuarioLogado._id);

    let isSync = lista.filter(e => e.sync).length > 0;
    let totalLocal = lista.length;
    let { count: totalOnline, naoLiberados } = await this.service.total();
    return { totalLocal, totalOnline, naoLiberados, isSync };
  }

  async listar(id, itemSelecionado, map = true) {
    const lista = await super.listar(
      id
        ? e => {
            if (itemSelecionado) {
              return e.atribuicao_id === itemSelecionado.id && e.id_usuario_inclusao === id;
            }
            return e.id_usuario_inclusao === id;
          }
        : undefined,
      false,
    );
    if (map) {
      return lista.map(e => {
        const temp = e.jsondata;
        delete e.jsondata;
        return { ...temp, ...e };
      });
    }

    return lista;
  }

  async listarParaAutorizar() {
    try {
      const resp = await this.service.listarParaAutorizar();
      if (resp.error) {
        throw new Error(resp.msg);
      }

      return resp.map(e => {
        const jsondata = e.jsondata;
        delete e.jsondata;
        return { ...jsondata, ...e };
      });
    } catch (e) {
      console.error(e);
      throw new Error(e.message);
    }
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   */
  getFieldList(props, state) {
    return [
      {
        name: "codigo",
        title: "Código",
        type: FormType.TEXT,
        disabled: true,
        break: true,
        cols: {
          xs: 12,
          md: 2,
          lg: 1,
        },
      },
      {
        name: "dataHora",
        title: "Data",
        type: FormType.DATE,
        disabled: false,
        cols: {
          xs: 12,
          md: 4,
          lg: 3,
        },
      },
      ...(props.tipo === 0
        ? [
            {
              name: "naoHouveColheita",
              title: "Não houve colheita",
              type: FormType.CHECK,
              disabled: props.readOnly,
              cols: {
                xs: 12,
                md: 4,
              },
              break: true,
            },
            {
              name: "horaInicial",
              title: "Hora inicial",
              type: FormType.TIME,
              disabled: props.readOnly,
              cols: {
                xs: 12,
                md: 3,
                lg: 2,
              },
            },
            {
              name: "horaFinal",
              title: "Hora final",
              type: FormType.TIME,
              disabled: props.readOnly,
              cols: {
                xs: 12,
                md: 3,
                lg: 2,
              },
              break: true,
            },
            {
              name: "colhedeirasProprias",
              title: "Nº colhedeiras Proprias",
              disabled: props.readOnly,
              format: {
                numeral: true,
                numeralDecimalMark: ",",
                delimiter: ".",
              },
              type: FormType.NUMBER,
              cols: {
                xs: 12,
                md: 3,
              },
            },
            {
              name: "colhedeirasTerceirizadas",
              title: "Nº colhedeiras Terceirizadas",
              disabled: props.readOnly,
              format: {
                numeral: true,
                numeralDecimalMark: ",",
                delimiter: ".",
              },
              type: FormType.NUMBER,
              cols: {
                xs: 12,
                md: 4,
                lg: 3,
              },
              break: true,
            },
            {
              name: "areaColhida",
              title: "Área colhida (HA)",
              disabled: props.readOnly,
              type: FormType.NUMBER,
              format: {
                numeral: true,
                numeralDecimalMark: ",",
                delimiter: ".",
              },
              cols: {
                xs: 12,
                md: 3,
              },
            },
            {
              name: "volume",
              title: "Volume (Kg)",
              disabled: props.readOnly,
              format: {
                numeral: true,
                numeralDecimalMark: ",",
                delimiter: ".",
              },
              type: FormType.NUMBER,
              cols: {
                xs: 7,
                sm: 9,
                md: 3,
              },
              break: false,
            },

            {
              name: "volumeSacas",
              title: "Volume (sacas)",
              format: {
                numeral: true,
                numeralDecimalMark: ",",
                delimiter: ".",
              },
              disabled: true,
              type: FormType.NUMBER,
              cols: {
                xs: 5,
                sm: 3,
                md: 3,
                lg: 2,
                xl: 2,
              },
              break: false,
            },

            {
              name: "produtividade",
              title: "Produtividade (sacas / área)",
              disabled: true,
              format: {
                numeral: true,
                numeralDecimalMark: ",",
                delimiter: ".",
              },
              type: FormType.NUMBER,
              cols: {
                xs: 12,
                md: 3,
              },
              break: true,
            },
            ...(props.itemSelecionado.cpr.produto === 6
              ? [
                  {
                    name: "valorBobinas",
                    title: "Valor total bobinas",
                    disabled: props.readOnly,
                    format: {
                      numeral: true,
                      numeralDecimalMark: ",",
                      delimiter: ".",
                    },
                    type: FormType.NUMBER,
                    cols: {
                      xs: 12,
                      md: 3,
                    },
                  },
                  {
                    name: "fardoes",
                    title: "Fardões",
                    type: FormType.NUMBER,
                    disabled: props.readOnly,
                    format: {
                      numeral: true,
                      numeralDecimalMark: ",",
                      delimiter: ".",
                    },
                    cols: {
                      xs: 12,
                      md: 3,
                    },
                  },
                  {
                    name: "fardinhos",
                    title: "Fardinhos",
                    type: FormType.NUMBER,
                    disabled: props.readOnly,
                    format: {
                      numeral: true,
                      numeralDecimalMark: ",",
                      delimiter: ".",
                    },
                    cols: {
                      xs: 12,
                      md: 3,
                    },
                  },
                ]
              : []),
            {
              name: "observacoes",
              title: "Observações",
              type: FormType.TEXT,
              disabled: props.readOnly,
              fieldProps: {
                multiline: true,
                rowsMax: 6,
                rows: 4,
              },
              cols: {
                xs: 12,
                md: 12,
                lg: 10,
                xl: 7,
              },
              break: true,
            },
            {
              name: "talhaoColhido",
              title: "Talhão colhido",
              disabled: props.readOnly,
              type: FormType.TEXT,
              cols: {
                xs: 12,
                md: 3,
                lg: 2,
              },
              break: true,
            },
          ]
        : [
            {
              name: "horaSaida",
              title: "Hora saída",
              type: FormType.TIME,
              disabled: props.readOnly,
              cols: {
                xs: 12,
                md: 3,
                lg: 2,
              },
              break: true,
            },
            {
              name: "pesoKg",
              title: "Peso (Kg)",
              type: FormType.NUMBER,
              disabled: props.readOnly,
              format: {
                numeral: true,
                numeralDecimalMark: ",",
                delimiter: ".",
              },
              cols: {
                xs: 12,
                md: 4,
                lg: 3,
              },
            },
            {
              name: "cultura",
              title: "Cultura",
              type: FormType.TEXT,
              disabled: true,
              cols: {
                xs: 12,
                md: 3,
                lg: 2,
              },
              break: true,
            },
            {
              name: "condutor",
              title: "Condutor",
              disabled: props.readOnly,
              type: FormType.TEXT,
              cols: {
                xs: 12,
                md: 8,
                lg: 6,
              },
              break: true,
            },
            {
              name: "placaCarreta",
              title: "Placa carreta",
              disabled: props.readOnly,
              type: FormType.TEXT,
              cols: {
                xs: 12,
                md: 3,
                lg: 2,
              },
            },
            {
              name: "placaCavalo",
              title: "Placa cavalo",
              type: FormType.TEXT,
              disabled: props.readOnly,
              cols: {
                xs: 12,
                md: 3,
                lg: 2,
              },
            },
            {
              name: "outraPlaca",
              title: "Outra placa",
              type: FormType.TEXT,
              disabled: props.readOnly,
              cols: {
                xs: 12,
                md: 3,
                lg: 2,
              },
              break: true,
            },
            {
              name: "modeloCaminhao",
              title: "Modelo caminhão",
              disabled: props.readOnly,
              type: FormType.TEXT,
              cols: {
                xs: 12,
                md: 8,
                lg: 6,
              },
              break: true,
            },
            {
              name: "tipoVolume",
              title: "Tipo do volume",
              disabled: props.readOnly,
              type: FormType.SELECT,
              cols: {
                xs: 12,
                md: 8,
                lg: 6,
              },
              break: true,
              allowNoSelection: true,
              items: [
                {
                  value: 0,
                  label: "Estimado",
                },
                {
                  value: 1,
                  label: "Comprovado",
                },
              ],
            },
            {
              name: "localEntrega",
              title: "Local entrega",
              disabled: props.readOnly,
              type: FormType.TEXT,
              cols: {
                xs: 12,
                md: 8,
                lg: 6,
              },
              break: true,
            },
            {
              name: "tipoArmazenamento",
              title: "Destino",
              disabled: props.readOnly,
              type: FormType.SELECT,
              allowNoSelection: true,
              items: [
                {
                  value: 1,
                  label: "Trading",
                },
                {
                  value: 2,
                  label: "Armaz. próprio",
                },
                {
                  value: 3,
                  label: "Desviado",
                },
              ],
              cols: {
                xs: 12,
                md: 8,
                lg: 6,
              },
              break: true,
            },
          ]),
      ...(props.inside || props.readOnly
        ? [
            {
              name: "liberado",
              title: "Concordo que está tudo correto e estes dados devem aparecer na plataforma!",
              type: FormType.CHECK,
              cols: {
                xs: 12,
              },
            },
          ]
        : []),
    ];
  }

  buscarPorLogin(login) {
    return this.service.buscarPorLogin(login);
  }

  remover(id) {
    return this.service.remover(id);
  }

  async salvar(acompanhamento, onSync, syncNow = false) {
    if (!acompanhamento.codigo) {
      acompanhamento.codigo = this.getGenericCode();
    }
    acompanhamento.sync = true;

    await super.salvar(acompanhamento, true); // salva no banco local

    if (!syncNow) {
      Swal.fire({
        title: "Informação",
        text: "Salvo localmente com sucesso!",
        type: "info",
        timer: 2000,
      });
      this.sleep(2000).then(() => {
        this.sync(true, onSync);
      });
    } else {
      await this.sync(true, onSync);
    }
  }

  sync(silently, onSync) {
    return this.service.sync("acompanhamento/", "acompanhamento/", silently, onSync);
  }
}
