import React, { useState, useEffect } from "react";
import Page from "../../components/Page";
import FormBuilder, { FormType } from "../../components/FormBuilder";
import PessoasController from "./controller";
import { IN_INSERT } from "../../redux/actionTypes";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setItemSelecionado, setFormState, setUsuarioLogado } from "../../redux/actions";
import { Button, Tooltip } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import Swal from "sweetalert2";

const controller = new PessoasController();
const FormPessoas = props => {
  const inistalState = {
    id: "",
    codigo: "",
    nome: "",
    login: "",
    password: "",
    password2: "",
    tipo: "",
  };
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    ...inistalState,
  });

  const handleChange = controller.handleChange(setState, () => state);

  useEffect(() => {
    const dados = props.showingInUserOption ? props.usuarioLogado : props.itemSelecionado;
    if (dados) {
      // debugger;
      const { _id, id, nome, email, login } = dados;
      const tipo = dados.role || dados.tipo;
      setState({
        codigo: id || _id,
        id: id || _id,
        nome,
        email,
        login,
        tipo: tipo === undefined ? 0 : tipo,
      });
    }
  }, [props.showingInUserOption, props.usuarioLogado, props.itemSelecionado]);

  const onSave = async e => {
    // debugger;
    if (
      props.formState === IN_INSERT ||
      // props.usuarioLogado.role === 1 ||
      props.showingInUserOption
    ) {
      if (state.password !== state.password2) {
        // debugger;
        Swal.fire({
          title: "Atenção",
          text: "As senhas nao são iguais!",
          type: "warning",
        });

        return;
      }
    }

    setLoading(true);
    const retorno = await controller.buscarPorLogin(state.login);
    if (retorno && retorno.length > 0 && retorno.filter(e => e.id !== state.id).length > 0) {
      Swal.fire({
        title: "Atenção",
        text: "O login já está sendo usado!",
        type: "warning",
      });
    }

    try {
      const resp = await controller.salvar({
        usuario: {
          ...state,
        },
      });

      if (!resp.error) {
        Swal.fire({
          title: "Informação",
          text: "Salvo com sucesso!",
          type: "info",
        });

        if (props.showingInUserOption) {
          const usuarioLogado = { ...props.usuarioLogado, ...state };
          props.setUsuarioLogado(usuarioLogado);
          window.sessionStorage.setItem("usuarioLogado", JSON.stringify(usuarioLogado));
          if (props.onSave) {
            props.onSave();
          }
        } else {
          window.location.href = "#/pessoas/list";
        }
      } else {
        Swal.fire({
          title: "Atenção",
          text: "Falha ao salvar, aguarde alguns instantes e tente novamente!",
          type: "warning",
        });
        console.info(resp);
      }
    } catch (e) {
      Swal.fire({
        title: "Atenção",
        text: "Falha ao salvar, aguarde alguns instantes e tente novamente!",
        type: "warning",
      });
      console.info(e);
    }
    setLoading(false);
  };

  return (
    <Page
      loading={loading}
      noMenu={props.noMenu}
      noHeader={props.noHeader}
      titlePage={!props.noHeader && !props.noMenu ? "Cadastro de Pessoas" : ""}
      noMargin={props.showingInUserOption}
      containerTitle={
        !props.noHeader &&
        !props.noMenu && (
          <Tooltip title="Voltar para a listagem">
            <Button
              variant="contained"
              color={"secondary"}
              style={{ alignSelf: "start", marginLeft: 10 }}
              onClick={() => {
                window.location.href = "#/pessoas/list";
              }}
            >
              <ArrowBack />
              Cancelar
            </Button>
          </Tooltip>
        )
      }
    >
      <FormBuilder
        state={state}
        handleChange={handleChange}
        onSaveButtonClick={onSave}
        rootPaper={false}
        fields={[
          {
            type: FormType.PANEL,
            title: "Dados Pessoais",
            cols: {
              xs: 12,
            },
            items: [
              ...(props.showingInUserOption
                ? []
                : [
                    {
                      title: "Código",
                      name: "codigo",
                      type: FormType.TEXT,
                      break: true,
                      disabled: true,
                      cols: {
                        xs: 12,
                        md: 4,
                        lg: 3,
                        xl: 2,
                      },
                    },
                  ]),
              ...[
                {
                  title: "Nome",
                  name: "nome",
                  type: FormType.TEXT,
                  fieldProps: {
                    maxLength: 100,
                  },
                  validation: {
                    required: true,
                  },
                  break: true,
                  cols: {
                    xs: 12,
                    md: 8,
                    lg: 7,
                    xl: 6,
                  },
                },
                {
                  title: "E-mail",
                  name: "email",
                  type: FormType.TEXT,
                  fieldProps: {
                    maxLength: 100,
                  },
                  break: true,
                  cols: {
                    xs: 12,
                    md: 8,
                    lg: 7,
                    xl: 6,
                  },
                },
                {
                  title: "Login",
                  name: "login",
                  type: FormType.TEXT,
                  fieldProps: {
                    maxLength: 50,
                    autocomplete: "nope",
                  },
                  validation: {
                    required: true,
                  },
                  disabled: !!state.codigo,
                  break: true,
                  cols: {
                    xs: 12,
                    md: 6,
                    xl: 4,
                  },
                },
                ...(props.usuarioLogado.role === 1 && !props.showingInUserOption
                  ? [
                      {
                        title: "Tipo de usuário",
                        name: "tipo",
                        type: FormType.SELECT,
                        allowNoSelection: false,
                        items: [
                          {
                            value: 0,
                            label: "Usuário comum",
                          },
                          {
                            value: 1,
                            label: "Admin",
                          },
                          {
                            value: 2,
                            label: "Auditoria",
                          },
                        ],
                        break: true,
                        cols: {
                          xs: 12,
                          md: 6,
                          xl: 4,
                        },
                      },
                    ]
                  : []),
              ],
            ],
          },
          ...(props.formState === IN_INSERT ||
          // props.usuarioLogado.role === 1 ||
          props.showingInUserOption
            ? [
                {
                  type: FormType.PANEL,
                  title: "Segurança",
                  cols: {
                    xs: 12,
                  },
                  items: [
                    {
                      title: "Senha",
                      name: "password",
                      type: FormType.PASSWORD,
                      fieldProps: {
                        maxLength: 50,
                        autocomplete: "new-password",
                      },
                      validation: {
                        required: true,
                      },
                      break: true,
                      cols: {
                        xs: 12,
                        md: 6,
                        xl: 4,
                      },
                    },
                    {
                      title: "Confirmação de senha",
                      name: "password2",
                      type: FormType.PASSWORD,
                      fieldProps: {
                        maxLength: 50,
                        // autocomplete: "false",
                        autocomplete: "new-password",
                        autosuget: false,
                      },
                      validation: {
                        required: true,
                      },
                      cols: {
                        xs: 12,
                        md: 6,
                        xl: 4,
                      },
                    },
                  ],
                },
              ]
            : []),
        ]}
      ></FormBuilder>
    </Page>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setItemSelecionado, setFormState, setUsuarioLogado }, dispatch);

const mapStateToProps = store => ({
  formState: store.formState.formState,
  usuarioLogado: store.sessionState.usuarioLogado,
  itemSelecionado: store.formState.itemSelecionado,
});

export default connect(mapStateToProps, mapDispatchToProps)(FormPessoas);
