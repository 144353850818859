import BaseController from "../../components/BaseController";
import PessoasService from "./service";

export default class PessoasController extends BaseController {
  constructor() {
    super("users");
    this.service = new PessoasService();
  }

  buscarPorLogin(login) {
    return this.service.buscarPorLogin(login);
  }

  salvar(pessoa) {
    if (pessoa.usuario.id) {
      return this.service.update(pessoa, true);
    } else {
      return this.service.salvar(pessoa);
    }
  }
}
