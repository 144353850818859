import React, { useState } from "react";
import Card from "../../../components/Card";
import { Line } from "react-chartjs-2";
import { FormControl, Select, OutlinedInput, MenuItem } from "@material-ui/core";

const Estatisticas = props => {
  const [filtro, setFiltro] = useState("um");

  function handleChange(event) {
    setFiltro(event.target.value);
    props.onChangeFilter(event.target.value);
  }

  return (
    <Card
      title={
        <div style={{ display: "flex", position: "relative", justifyContent: "space-between" }}>
          <span>Estatísticas</span>
          <div style={{ textTransform: "initial" }}>
            <FormControl variant="outlined">
              <Select
                value={filtro}
                unselectable={false}
                onChange={handleChange}
                input={<OutlinedInput name="age" id="outlined-age-simple" />}
              >
                <MenuItem value={"um"}>Último dia</MenuItem>
                <MenuItem value={"tres"}>Últimos 3 dias</MenuItem>
                <MenuItem value={"sete"}>Última semana</MenuItem>
                <MenuItem value={"quinze"}>Última quinzena</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      }
      cols={{ xl: 8, lg: 12 }}
    >
      <Line
        data={{
          labels: props.label,
          datasets: [
            {
              label: "Volume entregue na tradding",
              borderColor: "#a3a0fb",
              backgroundColor: ["#a3a0fb25"],
              data: [0, 0, 0, 0, 0, 0],
              fill: "start",
            },

            {
              label: "Volume de operações",
              borderColor: "#7fe2ff",
              backgroundColor: ["#7fe2ff55"],
              data: [0, 0, 0, 0, 0, 0],
              fill: "start",
            },
          ],
          ...props.data,
        }}
        options={{
          maintainAspectRatio: true,
          scales: {
            xAxes: [
              {
                ticks: {
                  autoSkip: false,
                  maxRotation: 90,
                },
              },
            ],
          },
        }}
      />
    </Card>
  );
};

export default Estatisticas;
