import React, { useEffect, useState } from "react";
import AcompanhamentoController from "./controller";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setItemSelecionado, setFormState } from "../../../redux/actions";
import { AppBar, Tabs, Tab, Fab, Dialog, IconButton, Toolbar, Typography } from "@material-ui/core";
import Menu from "../components/Menu";
import Header from "../../../components/Header";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { Add, Close } from "@material-ui/icons";
import FormAcompanhamento from ".";
import Loading from "../../../components/Loading";
import Swal from "sweetalert2";
import ListWithItItems from "../components/ListWithItems";
import { TabPanel } from "../../../components/TabItem";
import StatusNetwork from "../components/StatusNetwork";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#43425d",
    },
    secondary: {
      main: "#55aa55",
      contrastText: "#fff",
    },
  },
});

const controller = new AcompanhamentoController();

const ListAcompanhamentos = props => {
  const [acompanhamento, setAcompanhamento] = useState([]);
  const [acompanhamentoSilo, setAcompanhamentoSilo] = useState([]);
  const [acampanhamentoSelecionado, setAcampanhamentoSelecionado] = useState(null);
  const [tipoSelecionado, setTipoSelecionado] = useState(0);

  const [loading, setLoading] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [modalOpened, setModalOpened] = useState(false);
  const [showSync, setShowSync] = useState(false);

  const setShowButtomSync = force => {
    controller
      .total(props.usuarioLogado)
      .then(({ totalLocal, naoLiberados, totalOnline = 0, isSync }) => {
        console.info({ totalLocal, naoLiberados, totalOnline, isSync });
        if (naoLiberados > totalLocal || naoLiberados < totalLocal || isSync) {
          setShowSync(true);
        } else {
          setShowSync(false);
        }
      });
  };

  useEffect(() => {
    setShowButtomSync();

    // eslint-disable-next-line
  }, [acompanhamento, acompanhamentoSilo]);

  useEffect(() => {
    if (!props.itemSelecionado.id) {
      window.location.href = "#/monitor/monitoramentos";
    }
    listar(props.usuarioLogado.id || props.usuarioLogado._id);

    // eslint-disable-next-line
  }, [props.itemSelecionado.id, props.usuarioLogado]);

  const listar = id => {
    setLoading(true);
    controller.listar(id, props.itemSelecionado).then(lista => {
      setAcompanhamento(lista.filter(e => e.tipo === 0));
      setAcompanhamentoSilo(lista.filter(e => e.tipo === 1));
      setLoading(false);
    });
  };

  const forceLoad = async () => {
    setLoading(true);
    await controller.sync(true, () => {
      listar(props.usuarioLogado.id || props.usuarioLogado._id);
      setLoading(false);
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const closeModal = async () => {
    const resp = await Swal.fire({
      text: "Tem certeza que deseja cancelar?",
      type: "question",
      title: "Atenção",
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
      showCancelButton: true,
      focusConfirm: true,
    });

    if (resp.value) {
      setModalOpened(false);
    }
  };

  const showModal = (item, tipo) => {
    setModalOpened(true);
    setAcampanhamentoSelecionado(item);
    setTipoSelecionado(tipo);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Loading loading={loading} />
        <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        <Header
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          pageTitle={"Acompanhamento Diário - " + props.itemSelecionado.credora}
          hrefGoBack={"#/monitor/monitoramentos"}
        />

        <Dialog
          fullScreen
          open={modalOpened}
          onClose={closeModal}
          style={{ backgroundColor: "gray" }}
        >
          <AppBar>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={closeModal} aria-label="close">
                <Close />
              </IconButton>
              <Typography variant="body1" style={{ textTransform: "uppercase" }}>
                {value === 0 ? "Monitoramento lavoura" : "Silo/Armazém"}
              </Typography>
            </Toolbar>
          </AppBar>
          <FormAcompanhamento
            data={acampanhamentoSelecionado}
            itemSelecionado={props.itemSelecionado} //item atribuido ao monitor
            tipo={tipoSelecionado}
            reloadList={() => {
              listar(props.usuarioLogado.id || props.usuarioLogado._id);
            }}
            closeModal={() => {
              setModalOpened(false);
              listar(props.usuarioLogado.id || props.usuarioLogado._id);
            }}
            usuarioLogado={props.usuarioLogado}
          />
        </Dialog>
        <AppBar position="static" color={"primary"}>
          <Tabs value={value} onChange={handleChange} aria-label="Abas" variant="fullWidth">
            <Tab label="Monitoramento Lavoura" {...a11yProps(0)} />
            <Tab label="Monitoramento Silo/armazém" {...a11yProps(1)} />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          <ListWithItItems
            items={acompanhamento}
            itemSelecionado={props.itemSelecionado}
            tipo={0}
            onClick={showModal}
            showSync={showSync && props.isOnline}
            conClickForceLoad={forceLoad}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ListWithItItems
            items={acompanhamentoSilo}
            itemSelecionado={props.itemSelecionado}
            tipo={1}
            onClick={showModal}
            showSync={showSync && props.isOnline}
            conClickForceLoad={forceLoad}
          />
        </TabPanel>
        <Fab
          color="secondary"
          aria-label="add"
          style={{ position: "absolute", bottom: 50, right: 50 }}
          onClick={() => {
            showModal({}, value);
          }}
        >
          <Add />
        </Fab>
      </ThemeProvider>
    </>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setItemSelecionado, setFormState }, dispatch);

const mapStateToProps = store => ({
  itemSelecionado: store.formState.itemSelecionado,
  usuarioLogado: store.sessionState.usuarioLogado,
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusNetwork(ListAcompanhamentos));
