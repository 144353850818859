import React from "react";
import Card from "../../../components/Card";
import { Bar } from "react-chartjs-2";
const AreaTotalXAreaEfetiva = props => {
  const colors = ["#55d8fe", "#a3a0fb"];
  let items = [props.areaTotal, props.areaEfetiva];
  let labels = ["Área total", "Área efetiva"];

  const data = {
    labels: labels,
    datasets: [
      {
        backgroundColor: colors,
        data: items,
      },
    ],
  };

  const options = {
    legend: {
      display: false,
    },
    // tooltips: {
    //   enabled: false,
    // },
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          barPercentage: 0.8,
          maxBarThickness: 28,
        },
      ],
    },
  };

  return (
    <Card
      className="card-volume-tom"
      title={
        <div style={{ display: "flex", position: "relative", justifyContent: "space-between" }}>
          <span>Área total x Área efetiva</span>
        </div>
      }
      cols={props.cols || { md: 12, lg: 6 }}
    >
      <div className="container-chart">
        <Bar data={data} options={options} />
      </div>
    </Card>
  );
};

AreaTotalXAreaEfetiva.propTypes = {
  areaTotal: 0,
  areaEfetiva: 0,
};

export default AreaTotalXAreaEfetiva;
