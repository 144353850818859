import React, { useState, useEffect } from "react";
// import { Navbar, Nav } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./style.scss";
import Swal from "sweetalert2";
import { bindActionCreators } from "redux";
import { setUsuarioLogado, setFormState } from "../../redux/actions";
import { setCookie } from "../../utils";
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Notifications from "@material-ui/icons/Notifications";
import Forum from "@material-ui/icons/Forum";
import Menu from "@material-ui/icons/Menu";
import BlurCircular from "@material-ui/icons/BlurCircular";
import Tooltip from "@material-ui/core/Tooltip";
import FormPessoas from "../../pages/Pessoas/";

import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Popover,
  Badge,
  IconButton,
  Button,
  AppBar,
  Toolbar,
  Dialog,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Close, ArrowBack } from "@material-ui/icons";

function isTokenInvalido(usuarioLogado) {
  return !!usuarioLogado.token;
}

const onClickSair = async props => {
  const resp = await Swal.fire({
    title: "Atenção",
    html: "Tem certeza que deseja sair?",
    type: "warning",
    showCancelButton: true,
    confirmButtonText: "Sim, me tire daqui",
    cancelButtonText: "Não, mudei de ideia!",
  });

  if (resp.value) {
    sessionStorage.removeItem("usuarioLogado");
    setCookie("usuarioLogado=;expires= Thu, 01 Jan 1970 00:00:00 GMT");
    props.setUsuarioLogado({});
    props.setFormState(null);
    window.location.hash = "#/login";
  }
};

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  padding: {
    padding: theme.spacing(0, 0),
  },
  buttonOpcoeUsuario: {
    margin: theme.spacing(0),
    marginLeft: theme.spacing(1),
    fontSize: 14,
  },
}));

const StyledBadge2 = withStyles(theme => ({
  badge: {
    backgroundColor: "#ffc06a",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid #ffc06a",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const Header = props => {
  let { usuarioLogado } = props;
  const [anchorEl, setanchorEl] = useState(null);
  const [notificacoes] = useState(4);
  const [modalUserOpened, setModalUserOpened] = useState(false);
  const [littleScreen, setLittleScreen] = useState(false);

  let userSession = sessionStorage.getItem("usuarioLogado");
  if (userSession) {
    userSession = JSON.parse(userSession);

    if (usuarioLogado.nome !== userSession.nome) {
      usuarioLogado = { ...usuarioLogado, ...userSession };
      props.setUsuarioLogado(usuarioLogado);
    }
  }

  if (!usuarioLogado._id || !isTokenInvalido(usuarioLogado)) {
    window.location.hash = "#/login";
  }

  if (props.formState) {
    document.body.classList.add("onForm");
    document.querySelector("html").classList.add("onForm");
  } else {
    document.body.classList.remove("onForm");
    document.querySelector("html").classList.remove("onForm");
  }

  const onClickButtonOpcoes = e => {
    setanchorEl(anchorEl ? null : e.target);
  };

  const handleClose = () => {
    setanchorEl(null);
  };

  const open = Boolean(anchorEl);
  const classes = useStyles();

  useEffect(() => {
    const onResize = e => {
      if (window.document.body.offsetWidth <= 500 && !littleScreen) {
        setLittleScreen(true);
      } else if (window.document.body.offsetWidth > 500 && littleScreen) {
        setLittleScreen(false);
      }
    };

    window.addEventListener("resize", onResize);
    onResize();
    return () => {
      window.removeEventListener("resize", onResize);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Navbar
        sticky="top"
        bg={!window.location.hash.includes("#/monitor") ? "light" : "app"}
        expand="lg"
        variant="light"
      >
        {window.location.hash.includes("#/monitor") && (
          <>
            <div className="container-infor-header">
              {props.hrefGoBack ? (
                <IconButton
                  onClick={() => (window.location.href = props.hrefGoBack)}
                  className="icon-button"
                >
                  <ArrowBack style={{ color: "#9c9c9c" }} />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => props.setMenuOpen(!props.menuOpen)}
                  className="icon-button"
                >
                  <Menu style={{ color: "#9c9c9c" }} />
                </IconButton>
              )}
              <span className="page-title">{props.pageTitle}</span>
            </div>
          </>
        )}
        <Nav className="justify-content-end ml-auto container-user-logged-in">
          {!window.location.hash.includes("#/monitor") && (
            <>
              <IconButton>
                <BlurCircular style={{ color: "#9c9c9c" }} />
              </IconButton>

              <IconButton>
                <Forum style={{ color: "#9c9c9c" }} />
              </IconButton>
              <Tooltip title="Notificações" placement="bottom">
                <IconButton
                  aria-label={notificacoes + " mensagens pendentes"}
                  className={[classes.margin]}
                >
                  {usuarioLogado.notificacao && (
                    <StyledBadge2 badgeContent={" "} variant="dot">
                      <Notifications style={{ color: "#9c9c9c" }} />
                    </StyledBadge2>
                  )}

                  {!usuarioLogado.notificacao && <Notifications style={{ color: "#9c9c9c" }} />}
                </IconButton>
              </Tooltip>
            </>
          )}

          <div className="separator">|</div>
          <Button className={classes.buttonOpcoeUsuario}>
            <a
              className="link-name-user link-undecorated"
              href="#/"
              onClick={e => {
                e.preventDefault();
                onClickButtonOpcoes(e);
              }}
            >
              {usuarioLogado.nome}
              <ExpandMore className={"icon-opcoes-usuario " + (open ? "opened" : "")} />
            </a>
          </Button>

          <Popover
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClose={handleClose}
            style={{ zIndex: 99999 }}
            PaperProps={{
              style: { marginLeft: -20, marginTop: 10 },
              elevation: 3,
              className: "paper-acoes-usuario",
            }}
            BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.1)" } }}
            open={open}
            anchorEl={anchorEl}
          >
            <List
              component="nav"
              style={{
                minWidth: 250,
                width: "100%",
                maxWidth: 360,
              }}
              aria-label="mailbox folders"
            >
              <ListItem button>
                <ListItemText>
                  <b>Status</b>: online
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem button divider>
                <ListItemText
                  primary="Configurações da conta"
                  onClick={() => {
                    handleClose();
                    setModalUserOpened(true);
                  }}
                />
              </ListItem>
              {/* <Divider />
            <ListItem button divider>
              <ListItemText primary="Configurações da conta" />
            </ListItem>
            <ListItem button>
              <ListItemText primary="Feedback" />
            </ListItem> */}
              <Divider light />
              <ListItem
                button
                onClick={e => {
                  handleClose();
                  onClickSair(props);
                }}
              >
                <ListItemText>Sair</ListItemText>
              </ListItem>
            </List>
          </Popover>
          <a
            href="#/"
            onClick={e => {
              e.preventDefault();
              onClickButtonOpcoes(e);
            }}
          >
            <Avatar
              alt="Imagem avatar"
              src={usuarioLogado.imagemSrc}
              style={{ margin: 10, marginLeft: 0 }}
            >
              {!usuarioLogado.imagemSrc && <PersonIcon />}
            </Avatar>
          </a>
        </Nav>
      </Navbar>

      <Dialog
        maxWidth={littleScreen ? undefined : "md"}
        // style={{ minWidth: 500 }}
        fullScreen={littleScreen}
        fullWidth
        className={"not-margin-top"}
        open={modalUserOpened}
        onClose={() => {
          setModalUserOpened(false);
        }}
      >
        <AppBar position="relative">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setModalUserOpened(false);
              }}
              aria-label="close"
            >
              <Close />
            </IconButton>
            Meus Dados
          </Toolbar>
        </AppBar>
        <FormPessoas
          noHeader
          noMenu
          showingInUserOption
          itemSelecionado={props.usuarioLogado || {}}
          usuarioLogado={props.usuarioLogado || {}}
          formState={props.formState}
          onSave={() => {
            setModalUserOpened(false);
          }}
        ></FormPessoas>
      </Dialog>
    </>
  );
};

Header.propTypes = {
  menus: PropTypes.element,
  location: PropTypes.object,
  usuarioLogado: PropTypes.object,
  formState: PropTypes.string,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setUsuarioLogado, setFormState }, dispatch);

const mapStateToProps = store => ({
  usuarioLogado: store.sessionState.usuarioLogado,
  formState: store.formState.formState,
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
