import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./style.scss";
import Nav from "react-bootstrap/Nav";
import { default as logo } from "../../assets/logo-icon.svg";
import {
  Equalizer,
  Add,
  Sync,
  InsertChart,
  Person,
  DateRange,
  Info,
  Settings,
  HowToReg,
  LocalShipping,
  DoneAll,
  DeveloperBoard,
} from "@material-ui/icons";

const menus = [
  {
    href: "",
    icon: Equalizer,
    nome: "Dashboard",
    opened: true,
  },
  {
    href: "operacoes",
    icon: Sync,
    opened: true,
    nome: "Operações",
  },
  {
    href: "relatorio",
    icon: DateRange,
    opened: true,
    nome: "Relatórios",
  },
  {
    href: "cadastro/lista",
    icon: Add,
    opened: true,
    role: [1],
    nome: "Cadastro CPR",
  },
  {
    href: "emitente/lista",
    icon: LocalShipping,
    opened: true,
    role: [1],
    nome: "Emitente",
  },
  {
    href: "nomeacao",
    icon: HowToReg,
    nome: "Nomeação",
  },
  {
    href: "maparisco",
    icon: InsertChart,
    nome: "Risk Rating",
  },
  {
    href: "kanban",
    icon: DeveloperBoard,
    nome: "Kanban",
  },
  {
    href: "pessoas/list",
    icon: Person,
    opened: true,
    nome: "Usuários",
  },
  {
    href: "atribuicao/lista",
    icon: HowToReg,
    opened: true,
    nome: "Atribuição",
  },
  {
    href: "autorizacao/list",
    icon: DoneAll,
    opened: true,
    nome: "Autorização",
  },
  {
    href: "centralajuda",
    icon: Info,
    nome: "Central de ajuda",
  },
  {
    href: "configurações",
    icon: Settings,
    nome: "Configurações",
  },
];

const Menu = props => {
  const [urlLogo, setUrlLogo] = useState(localStorage.getItem("last-logo") || "./logo-cliente.png");
  return (
    <div className="container-menu">
      <div className="container-logo">
        <img
          src={urlLogo}
          alt="Logo"
          onLoad={() => {
            localStorage.setItem("last-logo", urlLogo);
          }}
          onError={e => {
            console.warn("Falha ao carregar logo, buscando alternativa...");
            if (urlLogo.includes("cliente.png")) {
              setUrlLogo(urlLogo.replace("png", "svg"));
            } else if (urlLogo.includes("cliente.svg")) {
              setUrlLogo(logo);
            }
          }}
        />
        <span className="nome-empresa">INSPECTO AGRI</span>
      </div>

      <Nav className="mr-auto">
        {menus.map((menu, i) => {
          if (!menu.role) {
            menu.role = [0, 1]; // user/admin
          }

          if (!menu.role.includes(props.usuarioLogado.role)) {
            return <></>;
          }

          return (
            <Nav.Link
              key={i}
              href={menu.opened || menu.preview ? "#/" + menu.href : undefined}
              className={window.location.href.endsWith("#/" + menu.href) ? "active" : ""}
            >
              {menu.icon && <menu.icon />}
              {menu.nome}{" "}
              {!menu.opened && (
                <i
                  style={{
                    marginLeft: 5,
                    fontSize: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  (em breve)
                </i>
              )}
            </Nav.Link>
          );
        })}
        {props.menus}
      </Nav>
      <span className="version">{props.version}</span>
    </div>
  );
};

Menu.propTypes = {
  children: PropTypes.element,
  menus: PropTypes.arrayOf(PropTypes.element),
  version: PropTypes.string,
};

const mapStateToProps = store => ({
  usuarioLogado: store.sessionState.usuarioLogado,
  version: store.sessionState.version,
});

export default connect(mapStateToProps)(Menu);
