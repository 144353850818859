import ServiceController from "./ServiceController";
import moment from "moment-timezone";
import TimeZone from "../utils/TimeZone";

export default class BaseController {
  constructor(name = "genericdata") {
    this.service = new ServiceController(name);
  }

  formatNumberCurrency = number => {
    const formater = Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" });
    return formater.format(number || 0);
  };

  formatNumber = number => {
    const formater = Intl.NumberFormat("pt-BR", {
      style: "decimal",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      currency: "BRL",
    });
    return formater.format(number || 0);
  };

  handleChange = (setState, getState) => input => {
    const name = input.name || input.target.name;
    const state = getState();
    let value = input.target.value;

    if (value instanceof Array) {
      value = value.filter(i => i !== "");
    }

    if (moment.isMoment(value)) {
      value = value.tz(TimeZone.default).toDate();
    }

    if (name) {
      if (input.target.type === "checkbox") {
        value = input.target.checked;
      } else if (value && value.file) {
        state[name + "nomeArquivoFisico"] = value.file.name;
        state[name + "modificacao"] = value.file.lastModified;
        state[name + "tamanho"] = value.file.size;
        state[name + "type"] = value.file.type || value.type;
      }

      state[name] = value;
      // console.info(state);
      setState({ ...state });
    } else {
      // console.warn("sem nome");
    }
  };

  async getListaEstados() {
    const estadoEcidades = await this.service.fetchData("estadosecidades", {
      url: "estadosecidades",
      type: "get",
      cache: true,
    });

    return estadoEcidades.map(e => ({ label: e.nome, value: e.id, sigla: e.sigla }));
  }

  async getListaCidades(estadoId) {
    let lista = [];
    const estadoEcidades = await this.service.fetchData("estadosecidades", {
      url: "estadosecidades",
      type: "get",
      cache: true,
      cacheInfinito: true,
    });
    if (estadoId) {
      lista = estadoEcidades.filter(estado => {
        return estado.id === estadoId;
      })[0].cidades;
    }

    return lista.map(e => ({ label: e.nome, value: e.id }));
  }

  getGenericCode() {
    return Math.round(Math.random() * Date.now());
  }

  sleep(time = 0) {
    return new Promise(r => {
      setTimeout(() => {
        r("wake up");
      }, time);
    });
  }

  async salvar(item = {}, clear = false) {
    const lista = await this.listar(null, null, false);
    // debugger;
    const id = item.id || item.codigo;
    let filtered = lista.filter(e => e.codigo === id || e.id === id)[0];
    if (filtered) {
      // filtered = { ...filtered, ...item };
      Object.keys(item).forEach(key => {
        filtered[key] = item[key];
      });

      if (clear) {
        let exists = false;
        Object.keys(filtered).forEach(keyExistente => {
          Object.keys(item).forEach(key => {
            if (keyExistente === key) {
              exists = true;
            }
          });

          if (!exists) {
            delete filtered[keyExistente];
          }
        });
      }
    } else {
      lista.push(item);
    }

    await this.service.setData(lista);
  }

  async remover(item = {}) {
    let lista = await this.listar();
    let index = -1;
    lista.every((it, i) => {
      if (item.id === it.id || item.codigo === it.codigo) {
        index = i;
        return false;
      }
      return true;
    });

    lista = this.removeItemArray(lista, index);
    await this.service.setData(lista);
  }

  removeItemArray(array = [], index) {
    if (array.length < index) {
      console.warn("o array nao tem o index passado");
    } else {
      array.splice(index, 1);
    }

    return array;
  }

  toNumber(n) {
    return Number(
      n
        .trim()
        .replace(/[.]/g, "")
        .replace(",", "."),
    );
  }

  /**
   * @returns {Promise<Array>} _
   */
  // async listar(filter) {
  //   let lista = await this.service.getData();
  //   if (!lista) {
  //     lista = [];
  //   }

  //   if (filter) {
  //     lista = lista.filter(filter);
  //   }

  //   return lista;
  // }

  async listar(
    filter = () => {
      return true;
    },
    cache = true,
  ) {
    let lista = await this.service.listar(cache);
    if (filter) {
      lista = lista.filter(filter);
    }

    return lista;
  }
}
