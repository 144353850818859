import React from "react";
import Card from "../../../components/Card";
import PropTypes from "prop-types";
import { Doughnut } from "react-chartjs-2";

const Operacoes = props => {
  const operacoes = props.data.total;
  const { laudos: laudosenviados } = props.data;
  /**
   * @type {Array<Number>}
   */
  const dataSet = props.data.itens.map(e => e.quantidade);
  const data = {
    datasets: [
      {
        backgroundColor: ["gray", "#55d8fe", "#ff8373", "#ffda83", "#a3a0fb", "#f0f2f8"],
        borderWidth: 0,
        data: [dataSet.filter(e => e > 0).length > 0 ? 0 : 1, ...dataSet],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    middleText: "",
    cutoutPercentage: 60,
    tooltips: {
      enabled: false,
    },
    legend: {
      display: false,
    },
  };

  return (
    <Card
      className="card-operacoes"
      title={props.titleChart}
      cols={props.cols || { md: 12, lg: 6 }}
    >
      <style>
        {`@media all and (max-width: 1600px) and (min-width: 1200px) {
          .card-operacoes__container {
              flex-direction: column-reverse;
          }
      }

      @media all and (max-width: 1050px) and (min-width: 992px) {
          .card-operacoes__container {
              flex-direction: column-reverse;
          }
      }`}
      </style>

      {operacoes > 0 && (
        <div className="header-total">
          <span className="header-total-title">Total Operações</span>
          <span className="header-total-valor">{operacoes} operações</span>
        </div>
      )}

      {laudosenviados > 0 && (
        <div className="header-total">
          <span className="header-total-title">Laudos Recebidos</span>
          <span className="header-total-valor">{laudosenviados} laudos</span>
        </div>
      )}

      <div className="card-operacoes__container">
        <div className="card-operacoes__container-chart">
          <Doughnut data={data} options={options} />
        </div>
        <div className="card-operacoes__container-information">
          {props.data.itens.map((item, i) => {
            return (
              <div key={"item-card-operacoes-" + i}>
                <div className="card-operacoes__container-information-rounded info"></div>
                <span className="text">{item.text}</span>
                <span className="info-quantidade">{item.quantidade || 0} operações</span>
              </div>
            );
          })}
        </div>
      </div>
    </Card>
  );
};

Operacoes.propTypes = {
  cols: PropTypes.object,
  nomeada: PropTypes.number,
  monitorando: PropTypes.number,
  aguardandoColheita: PropTypes.number,
  colhendo: PropTypes.number,
  data: PropTypes.shape({
    itens: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        quantidade: PropTypes.number,
        className: PropTypes.string,
      }),
    ),
    total: PropTypes.number,
    laudos: PropTypes.number,
  }),
};

Operacoes.defaultProps = {
  liquidada: 0,
  monitorando: 0,
  aguardandoColheita: 0,
  colhendo: 0,
  total: 0,
  data: {
    total: 0,
    itens: [],
    laudos: 0,
  },
};

export default Operacoes;
