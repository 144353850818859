import ServiceController from "../../components/ServiceController";

export default class PessoasService extends ServiceController {
  constructor() {
    super("users");
  }
  listar() {
    return this.fetchData(undefined, {
      url: this.path + "/",
      type: "get",
    });
  }

  /**
   *
   * @param {String} login
   * @returns {Promise<[{id: number, nome: string}]>} _
   */
  buscarPorLogin(login) {
    return this.fetchData(undefined, {
      url: this.path + "/?login=" + login,
      type: "get",
      cache: false,
    });
  }

  salvar(pessoa) {
    return this.fetchData(undefined, {
      url: this.path + "/",
      type: "post",
      data: pessoa,
    });
  }

  update(pessoa) {
    return this.fetchData(undefined, {
      url: this.path + "/update",
      type: "post",
      data: pessoa,
      clearCache: true,
    });
  }
}
