import React from "react";
import { Grid, Typography } from "@material-ui/core";
export const Title = props => {
  return (
    <Grid xs={12} {...props.cols}>
      <Typography
        variant="body2"
        style={{
          ...props.style,
        }}
      >
        {props.children}
      </Typography>
    </Grid>
  );
};

export const Description = props => {
  return (
    <Grid xs={12} {...props.cols}>
      <Typography variant="subtitle2">{props.children || "Não informado"}</Typography>
    </Grid>
  );
};

export const Item = props => {
  return (
    <Grid item xs={12} {...props.cols} style={{ padding: 0 }}>
      {props.children}
    </Grid>
  );
};
