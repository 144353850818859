import { default as momentTz } from "moment-timezone";

const TimeZone = {
  default: "America/Sao_Paulo",
};

export default TimeZone;
/**
 * @returns {momentTz.Moment}
 */
export const moment = function(...args) {
  console.info(args);
  return momentTz.call(this, ...args).tz(TimeZone.default);
};
