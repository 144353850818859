import BaseController from "../../components/BaseController";
import AnexoService from "./AnexoService";

class AnexoController extends BaseController {
  constructor() {
    super("anexo");
    this.service = new AnexoService();
  }

  async listar(
    filter = () => {
      return true;
    },
    cache = true,
    cprCodigo,
  ) {
    const lista = await this.service.listar(cache, cprCodigo);

    if (filter && filter instanceof Function) {
      return lista.filter(filter);
    } else {
      return lista;
    }
  }

  async salvar(item) {
    return this.service.salvar(item);
  }

  async remover(item = {}) {
    return this.service.remover(item);
  }
}

export default AnexoController;
