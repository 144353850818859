import React, { useState, useEffect } from "react";
import { Typography, Box } from "@material-ui/core";
import { WifiOff } from "@material-ui/icons";
import "./StatusNetwork.scss";
// import ServiceController from "../../../components/ServiceController";
export const LAST_STATE_KEY = "LAST-NETWORK-STATE";

export const setlastStatus = status => {
  window.sessionStorage.setItem(LAST_STATE_KEY, status);
};

const StatusNetwork = Child => {
  const HOC = props => {
    const [isOnline, setIsOnline] = useState(
      window.sessionStorage.getItem(LAST_STATE_KEY) === "true",
    );

    // const reconectar = async e => {
    //   debugger;
    //   e.preventDefault();
    //   e.stopPropagation();
    //   try {
    //     const online = await new ServiceController("StatusNetwork").checkStatus();
    //     if (online) {
    //       onOnline();
    //     }
    //   } catch (e) {}
    // };

    const onOnline = () => {
      setIsOnline(true);
      setlastStatus(true);
    };

    const onOffline = () => {
      setIsOnline(false);
      setlastStatus(false);
    };

    useEffect(() => {
      const lastState = window.sessionStorage.getItem(LAST_STATE_KEY) === "true";
      setIsOnline(lastState);
      window.addEventListener("online", onOnline);
      window.addEventListener("offline", onOffline);

      return () => {
        window.removeEventListener("online", onOnline);
        window.removeEventListener("offline", onOffline);
      };
    }, []);

    return (
      <>
        <Box className={"status-network " + (isOnline ? "online" : "offline")}>
          <Typography
            className={"status-network__text"}
            variant={"subtitle2"}
            style={{ textAlign: "center", color: "#f50057" }}
          >
            Você está offline <WifiOff />
            {/* <Button
              variant="outlined"
              size="small"
              style={{ color: "white", borderColor: "white", maxHeight: 25 }}
              onClick={reconectar}
            >
              Reconectar
            </Button> */}
          </Typography>
          <Child {...props} isOnline={isOnline} />
        </Box>
      </>
    );
  };

  return HOC;
};

export default StatusNetwork;
