import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TextField, FormControl, Select, MenuItem, OutlinedInput } from "@material-ui/core";
import FormLabel from "react-bootstrap/FormLabel";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import EnderecoController from "./controller";
const controller = new EnderecoController();

const Endereco = props => {
  const logradNome = "rua" + props.prefix;
  const numeroNome = "numero" + props.prefix;
  const bairroNome = "bairro" + props.prefix;
  const estadoNome = "estado" + props.prefix;
  const cidadeName = "cidade" + props.prefix;
  const estadoValue = props.state[estadoNome];

  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);

  useEffect(() => {
    controller.getListaEstados().then(estados => {
      setEstados(estados);
    });
  }, [setEstados]);

  useEffect(() => {
    controller.getListaCidades(estadoValue).then(cidades => {
      setCidades(cidades);
    });
  }, [estadoValue]);

  return (
    <props.RootComponent>
      <Form.Row>
        <Form.Group
          xs={12}
          sm={12}
          md={8}
          lg={7}
          xl={5}
          key={"col-" + logradNome + "-"}
          as={Col}
          // controlId={"validation-" + logradNome}
        >
          <FormLabel htmlFor={logradNome}>Logradouro</FormLabel>
          {/* <InputLabel htmlFor={cidadeName}>Logradouro</InputLabel> */}
          <TextField
            variant={"outlined"}
            helperText={props.lograd.feedbackInvalid || props.lograd.feedback}
            name={logradNome}
            id={logradNome}
            placeholder="Avenida/Rua/Travessa"
            required={props.lograd.required}
            disabled={props.lograd.disabled}
            value={props.state[logradNome]}
            onChange={e => {
              if (props.handleChange) {
                props.handleChange.call(e.target, e);
              }
            }}
            {...props.lograd.fieldProps}
          />
        </Form.Group>
        <Form.Group
          xs={12}
          sm={3}
          md={4}
          lg={2}
          xl={1}
          key={"col-" + numeroNome + "-"}
          as={Col}
          // controlId={"validation-" + numeroNome}
        >
          <FormLabel htmlFor={numeroNome}>Número</FormLabel>
          {/* <InputLabel htmlFor={cidadeName}>Logradouro</InputLabel> */}
          <TextField
            variant={"outlined"}
            helperText={props.numero.feedbackInvalid || props.numero.feedback}
            name={numeroNome}
            id={numeroNome}
            // placeholder="Avenida/Rua/Travessa"
            required={props.numero.required}
            disabled={props.numero.disabled}
            value={props.state[numeroNome]}
            onChange={e => {
              if (props.handleChange) {
                props.handleChange.call(e.target, e);
              }
            }}
            {...props.numero.fieldProps}
          />
        </Form.Group>
        <Form.Group
          xs={12}
          sm={9}
          md={4}
          lg={3}
          xl={2}
          key={"col-" + bairroNome + "-"}
          as={Col}
          // controlId={"validation-" + bairroNome}
        >
          <FormLabel htmlFor={bairroNome}>Bairro</FormLabel>
          {/* <InputLabel htmlFor={cidadeName}>Logradouro</InputLabel> */}
          <TextField
            variant={"outlined"}
            helperText={props.bairro.feedbackInvalid || props.bairro.feedback}
            name={bairroNome}
            id={bairroNome}
            // placeholder="Avenida/Rua/Travessa"
            required={props.bairro.required}
            disabled={props.bairro.disabled}
            value={props.state[bairroNome]}
            onChange={e => {
              if (props.handleChange) {
                props.handleChange.call(e.target, e);
              }
            }}
            {...props.bairro.fieldProps}
          />
        </Form.Group>
        <Form.Group
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
          key={"col-" + estadoNome + "-"}
          as={Col}
          // controlId={"validation-" + estadoNome}
        >
          <FormLabel htmlFor={estadoNome}>Estado</FormLabel>
          {/* <InputLabel htmlFor={cidadeName}>Logradouro</InputLabel> */}
          <FormControl variant="outlined">
            <Select
              name={estadoNome}
              id={estadoNome}
              required={props.estado.required}
              disabled={props.estado.disabled}
              value={props.state[estadoNome]}
              displayEmpty
              onChange={props.handleChange}
              {...props.estado.fieldProps}
              input={<OutlinedInput name={estadoNome} id={estadoNome} />}
            >
              {[{ label: "Selecione uma opção", value: "" }, ...estados].map((item, i) => {
                return (
                  <MenuItem key={estadoNome + "-" + i} value={item.value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Form.Group>
        <Form.Group
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
          key={"col-" + cidadeName + "-"}
          as={Col}
          // controlId={"validation-" + cidadeName}
        >
          <FormLabel htmlFor={cidadeName}>Cidade</FormLabel>
          {/* <InputLabel htmlFor={cidadeName}>Logradouro</InputLabel> */}
          <FormControl variant="outlined">
            <Select
              name={cidadeName}
              id={cidadeName}
              required={props.cidade.required}
              disabled={props.cidade.disabled}
              value={props.state[cidadeName]}
              displayEmpty
              onChange={props.handleChange}
              {...props.cidade.fieldProps}
              input={<OutlinedInput name={cidadeName} id={estadoNome} />}
            >
              {[{ label: "Selecione uma opção", value: "" }, ...cidades].map((item, i) => {
                return (
                  <MenuItem key={cidadeName + "-" + i} value={item.value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Form.Group>
      </Form.Row>
    </props.RootComponent>
  );
};

Endereco.propTypes = {
  handleChange: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  RootComponent: PropTypes.node,
};

Endereco.defaultProps = {
  RootComponent: React.Fragment,
  lograd: {},
  numero: {},
  bairro: {},
  estado: { items: [{ label: "Selecione uma opção", value: "" }] },
  cidade: { items: [{ label: "Selecione uma opção", value: "" }] },
};
export default Endereco;
