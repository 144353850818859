import BaseController from "../../components/BaseController";
import { FormType } from "../../components/FormBuilder";
import { Add } from "@material-ui/icons";
import CprService from "./service";
import produtos from "../../utils/produtos";

class Controller extends BaseController {
  constructor() {
    super("cpr");
    this.service = new CprService();
  }

  async getListaCatorio(estado) {
    let lista = [];
    if (estado === 5) {
      lista = [
        {
          nome:
            "Cartório de Registro de Imóveis, Títulos e Documentos e Civis das Pessoas Jurídicas",
          id: 1,
          cidadeId: 2928901,
        },
        {
          nome: "Koerner Tabelionato de Notas e Protesto",
          id: 2,
          cidadeId: 2919553,
        },
        {
          nome: "Cartório do 2º Tabelionato de Notas",
          id: 3,
          cidadeId: 2903201,
        },
        {
          nome: "Cartório de Registro de Imóveis",
          id: 5,
          cidadeId: 2909307,
        },
        {
          nome: "Cartório Lissner - Tabelionato de Notas e Protestos",
          id: 6,
          cidadeId: 2926202,
        },
      ];
    } else if (estado === 10) {
      lista = [
        {
          nome: "Cartório do 2º Ofício",
          id: 4,
          cidadeId: 2102804,
        },
        {
          nome: "Cartório do 1º Ofício",
          id: 7,
          cidadeId: 2101400,
        },
      ];
    }

    return lista.map(e => ({ label: e.nome, value: e.id, cidadeId: e.cidadeId }));
  }

  /**
   * @returns {[{
    items: [{
      label:  String,
      value: String|Number
    }],
    multiple: boolean ,
    break: boolean ,
    type: String ,
    noSelectionLabel: ""|"Selecione uma opção",
    format: {
      // @see https://nosir.github.io/cleave.js/
      numeral: true| false,
      numeralDecimalMark: "."|",",
      delimiter: "."|",",
      cpf: true|false,
      cnpj: true|false,
    },
    validation: {
      required: true|false
    },
    tooltip: {
      title: "",
      placement: "bottom"|"top"|"left"|"right"
    },
    adornment: "",
    allowNoSelection: true|false,
    name: String ,
    title: String ,
    disabled: true|false ,
    fieldProps: {},
    cols: {
      xs: 0|1|2|3|4|5|6|7|8|9|10|11|12 ,
      sm: 0|1|2|3|4|5|6|7|8|9|10|11|12 ,
      md: 0|1|2|3|4|5|6|7|8|9|10|11|12 ,
      lg: 0|1|2|3|4|5|6|7|8|9|10|11|12 ,
      xl: 0|1|2|3|4|5|6|7|8|9|10|11|12 ,
    },
  }]}
  *  @param {{}} state
  *  @param {Function} onClickAddEmitente
  */
  getFieldList(state, onClickAddEmitente) {
    const moedas = [
      {
        value: "1",
        label: "Real",
        simbolo: "R$",
      },
      {
        value: "2",
        label: "Dolar",
        simbolo: "$",
      },
    ];
    const moedaSelecionada = moedas.filter(x => x.value === state.moeda)[0];

    const emitentes = state.emitentes.map(e => {
      // const cidadeSelecionada = null;
      // state.cidades.filter(c => {
      //   console.info(c);
      //   return c.value === e.cidade;
      // })[0];

      return {
        value: e.codigo,
        label: e.nome,
      };
    });

    const listaFazendasEmitente = state.listaFazendasEmitente.map(fazenda => {
      return {
        label: fazenda.nome,
        value: fazenda.codigo.toString(),
      };
    });

    const listaMatriculasEmitente = state.listaMatriculasEmitente.map(matricula => {
      return {
        value: matricula.nome,
        label: matricula.nome,
      };
    });
    return [
      {
        type: FormType.TEXT,
        name: "codigo",
        title: "Código",
        disabled: true,
        cols: {
          xs: 12,
          sm: 2,
          md: 1,
        },
      },
      {
        type: FormType.SELECT,
        name: "situacao",
        title: "Situação",
        validation: {
          required: true,
        },
        items: [
          {
            label: "Barter safra atual",
            value: "1",
          },
          {
            label: "Renegociação",
            value: "2",
          },
          {
            label: "CPRF",
            value: "3",
          },
          {
            label: "Acordo judicial",
            value: "4",
          },
          {
            label: "Parcela",
            value: "5",
          },
        ],
        cols: {
          xs: 12,
          sm: 6,
          md: 5,
          lg: 4,
        },
      },
      {
        name: "safra",
        type: FormType.SELECT,
        title: "Safra",
        validation: {
          required: true,
        },
        items: [
          {
            value: 2018,
            label: "2018/2019 - safra",
          },
          {
            value: 2019,
            label: "2019/2020 - safra",
          },
          {
            value: 2020,
            label: "2020/2020 - safra",
          },
          {
            value: 20201,
            label: "2020/2021 - safra",
          },
          {
            value: 2022,
            label: "2021/2022 - safra",
          },
          {
            value: 2023,
            label: "2022/2023 - safra",
          },
          {
            value: 2024,
            label: "2023/2024 - safra",
          },
          {
            value: 2025,
            label: "2024/2025 - safra",
          },
        ],
        cols: {
          xs: 12,
          sm: 4,
          md: 3,
          lg: 2,
        },
        break: true,
      },
      {
        name: "emitente",
        type: FormType.SELECT,
        title: "Emitente",
        validation: {
          required: true,
        },
        items: emitentes,
        cols: {
          xs: 12,
          sm: 7,
          md: 5,
          lg: 4,
        },
      },
      {
        type: FormType.BUTTON,
        title: "Novo emitente",
        fieldProps: {
          color: "primary",
          variant: "contained",
          onClick: onClickAddEmitente,
        },
        icon: Add,
        cols: {
          xs: 12,
          sm: 5,
          md: 6,
          lg: 3,
        },
      },

      {
        name: "credor",
        type: FormType.TEXT,
        title: "Credor",
        validation: {
          required: true,
        },
        cols: {
          xs: 12,
          md: 6,
          lg: 5,
        },
        break: true,
      },
      {
        name: "analistaCredito",
        type: FormType.TEXT,
        title: "Analista de crédito",
        validation: {
          required: true,
        },
        cols: {
          xs: 12,
          md: 5,
        },
        break: false,
      },
      {
        type: FormType.SPACER,
        cols: {
          sm: 0,
          md: 1,
          lg: 2,
        },
      },
      {
        name: "rtv",
        type: FormType.TEXT,
        title: "RTV",
        validation: {
          required: true,
        },
        cols: {
          xs: 12,
          md: 6,
          lg: 5,
        },
        break: true,
      },
      {
        name: "produto",
        type: FormType.SELECT,
        title: "Produto",
        validation: {
          required: true,
        },
        items: produtos,
        cols: {
          xs: 12,
          md: 3,
        },
        break: false,
      },
      {
        type: FormType.NUMBER,
        title: "Volume " + (state.produto === 6 ? "(arrobas)" : "(sacas)"),
        name: "volume",
        validation: {
          required: true,
        },
        format: {
          numeral: true,
          numeralDecimalMark: ",",
          delimiter: ".",
        },
        cols: {
          sm: 12,
          md: 2,
          lg: 2,
        },
      },
      {
        type: FormType.SPACER,
        cols: {
          sm: 0,
          md: 1,
          lg: 2,
        },
      },
      {
        name: "tipooperacao",
        type: FormType.SELECT,
        title: "Tipo de operação",
        disabled: true,
        validation: {
          required: true,
        },
        items: [
          {
            value: 1,
            label: "AGRO",
          },
        ],
        cols: {
          xs: 12,
          md: 3,
          lg: 2,
        },
        break: false,
      },
      {
        name: "graupenhor",
        type: FormType.SELECT,
        title: "Grau do penhor",
        validation: {
          required: true,
        },
        items: [
          {
            value: "1",
            label: "1º grau",
          },
          {
            value: "2",
            label: "2º grau",
          },
          {
            value: "3",
            label: "3º grau",
          },
        ],
        cols: {
          xs: 12,
          md: 3,
          lg: 2,
        },
        break: true,
      },
      {
        name: "contrato",
        type: FormType.TEXT,
        title: "Contrato",
        validation: {
          required: true,
        },
        cols: {
          xs: 12,
          md: 3,
        },
        break: false,
      },
      {
        name: "tipocontrato",
        type: FormType.RADIO,
        title: "Tipo Contrato",
        items: [
          {
            value: "1",
            label: "Financeira",
          },
          {
            value: "2",
            label: "Barter",
          },
        ],
        cols: {
          xs: 12,
          sm: 6,
          md: 5,
          lg: 4,
        },
        break: false,
      },
      {
        name: "registro",
        type: FormType.TEXT,
        title: "Registro",
        cols: {
          xs: 12,
          sm: 6,
          md: 2,
        },
        break: true,
      },
      {
        name: "estado",
        type: FormType.SELECT,
        title: "Estado",
        validation: {
          required: true,
        },
        items: state.estados,
        cols: {
          xs: 12,
          sm: 6,
          md: 2,
        },
      },
      {
        name: "cartorio",
        type: FormType.SELECT,
        title: "Cartório",
        validation: {
          required: false,
        },
        items: state.cartorios,
        cols: {
          xs: 12,
          sm: 6,
          md: 2,
        },
        break: false,
      },
      {
        name: "cidade",
        type: FormType.SELECT,
        title: "Cidade",
        items: state.cidades,
        disabled: true,
        noSelectionLabel: "Nenhum cartório selecionado",
        tooltip: !state.cartorio
          ? {
              title: "A cidade irá aparecer quando o cartório for selecionado!",
            }
          : undefined,
        cols: {
          xs: 12,
          sm: 6,
          md: 2,
        },
        break: true,
      },
      {
        name: "fazendas",
        type: FormType.SELECT,
        title: "Fazendas",
        validation: {
          required: true,
        },
        fieldProps: {
          displayEmpty: false,
        },
        multiple: true,
        items: listaFazendasEmitente,
        cols: {
          md: 12,
          lg: 9,
        },
        break: true,
      },
      {
        name: "matriculas",
        type: FormType.SELECT,
        title: "Matriculas",
        validation: {
          required: true,
        },
        fieldProps: {
          displayEmpty: false,
          isMulti: true,
        },
        multiple: true,

        items: listaMatriculasEmitente,
        cols: {
          md: 12,
          lg: 9,
        },
        break: true,
      },
      {
        type: FormType.NUMBER,
        title: "Área total",
        name: "areatotal",
        format: {
          numeral: true,
          numeralDecimalMark: ",",
          delimiter: ".",
        },
        cols: {
          xs: 12,
          sm: 6,
          md: 3,
          lg: 2,
        },
      },
      {
        type: FormType.NUMBER,
        title: "Área de garantia",
        name: "areagarantida",
        cols: {
          xs: 12,
          sm: 6,
          md: 3,
          lg: 2,
        },
        format: {
          numeral: true,
          numeralDecimalMark: ",",
          delimiter: ".",
        },
        break: true,
      },
      {
        type: FormType.SELECT,
        title: "Moeda",
        name: "moeda",
        items: moedas,
        allowNoSelection: false,
        disabled: !!state.valormonetario,
        tooltip: !!state.valormonetario
          ? {
              title: "Apague o valor monetário para alterar a moeda. ",
            }
          : undefined,
        cols: {
          xs: 12,
          sm: 3,
          md: 2,
          lg: 1,
        },
      },
      {
        type: FormType.NUMBER,
        title: "Valor monetário",
        validation: {
          required: state.tipocontrato === "1",
        },
        name: "valormonetario",
        cols: {
          xs: 12,
          sm: 4,
          md: 3,
          lg: 2,
        },
        format: {
          numeral: true,
          numeralDecimalMark: moedaSelecionada.value === "1" ? "," : ".",
          delimiter: moedaSelecionada.value === "1" ? "." : ",",
        },
        adornment: moedaSelecionada.simbolo,
      },
      {
        type: FormType.NUMBER,
        title: "Valor da saca",
        // validation: {
        //   required: state.tipocontrato === "1",
        // },
        tooltip: {
          title: "",
        },
        name: "valorSaca",
        cols: {
          xs: 12,
          sm: 4,
          md: 3,
          lg: 2,
        },
        format: {
          numeral: true,
          numeralDecimalMark: moedaSelecionada.value === "1" ? "," : ".",
          delimiter: moedaSelecionada.value === "1" ? "." : ",",
        },
        adornment: moedaSelecionada.simbolo,
        break: true,
      },

      {
        type: FormType.TEXT,
        title: "Assinatura de dados",
        name: "assinaturadedados",
        cols: {
          xs: 12,
          sm: 8,
          md: 6,
        },
        break: true,
      },
      {
        type: FormType.DATE,
        title: "Data vencimento",
        name: "datavencimento",
        validation: {
          required: true,
        },
        cols: {
          xs: 12,
          sm: 6,
          md: 5,
          lg: 3,
        },
        break: true,
      },
      {
        type: FormType.TEXT,
        title: "Produtividade mínima",
        name: "produtividademinima",
        cols: {
          xs: 12,
          sm: 6,
          md: 5,
          lg: 3,
        },
        break: true,
      },
      {
        type: FormType.TEXT,
        title: "Número do documento",
        name: "numerodocumento",
        cols: {
          xs: 12,
          sm: 6,
          md: 4,
          lg: 3,
        },
        break: true,
      },
      {
        type: FormType.FILE,
        name: "arquivo",
        title: "Documento (Cópia da CPR escaneada)",
        fieldProps: {
          fisicName: state.arquivonomeArquivoFisico,
          url: state.arquivo,
          acceptTypes: "application/pdf",
          showType: false,
          showModification: false,
          showSize: false,
        },
        cols: {
          xs: 12,
          sm: 6,
          lg: 4,
        },
      },
      {
        type: FormType.PANEL,
        title: "Dados da entrega",
        name: "dadosdaentrega",
        fieldProps: {
          elevation: 0,
          style: {
            border: "1px solid #f0f0f7",
          },
        },
        items: [
          {
            type: FormType.ENDERECO,
            prefix: "Entrega",
            cols: {
              xs: 12,
            },
            break: true,
          },
          {
            type: FormType.DATE,
            title: "Data entrega inicial",
            name: "dataentregainicial",
            cols: {
              xs: 12,
              sm: 6,
              md: 5,
              lg: 3,
            },
          },
          {
            type: FormType.DATE,
            title: "Data entrega final",
            name: "dataentregafinal",
            cols: {
              xs: 12,
              sm: 6,
              md: 5,
              lg: 3,
            },
            break: true,
          },
        ],
        cols: {
          xs: 12,
        },
        break: true,
      },
    ];
  }

  async listaAtribuir() {
    return this.service.listaAtribuir();
  }

  async listar(
    filter = () => {
      return true;
    },
    cache = true,
  ) {
    const lista = await this.service.listar(cache);
    return lista.filter(filter);
  }

  async salvar(item) {
    return this.service.salvar(item);
  }
}

export default Controller;
