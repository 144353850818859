import React from "react";
import Card from "../../../components/Card";
import PropTypes from "prop-types";
import { Doughnut, Chart } from "react-chartjs-2";
import BaseController from "../../../components/BaseController";

const controller = new BaseController();

const VolumeSacas = props => {
  let percent = props.total ? ((props.atingido * 100) / props.total).toFixed(2) : "0";
  if (percent.split(".")[1] === "00") {
    percent = Math.round(percent);
  }

  const data = {
    labels: ["Atingido", "Restante"],
    datasets: [
      {
        weight: 25,
        backgroundColor: ["#a3a0fb", "#eae9fe"],
        borderWidth: 0,
        data: [props.atingido, props.restante],
      },
    ],
  };

  const options = {
    middleText: percent + "%",
    cutoutPercentage: 85,
    maintainAspectRatio: false,
    tooltips: {
      enabled: false,
    },
    legend: {
      display: false,
    },
  };

  Chart.pluginService.register({
    beforeDraw: function(chart) {
      if (chart.config.options.middleText) {
        var width = chart.chart.width,
          height = chart.chart.height,
          ctx = chart.chart.ctx;

        ctx.restore();
        ctx.clearRect(0, 0, width, height); // fix problem with old text on canvas
        // eslint-disable-next-line quotes
        ctx.font = 0.9 + 'em "Segoe UI", Roboto, "Helvetica Neue", Aria, sans-serif';
        ctx.textBaseline = "middle";

        var text = chart.config.options.middleText,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;

        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    },
  });

  return (
    <Card className="card-valor-milhoes" title="Volume (Sacas)" cols={props.cols || { md: 12 }}>
      <div className="header-total">
        <span className="header-total-title">Total esperado</span>
        <span className="header-total-valor">{controller.formatNumber(props.total)}</span>
      </div>

      {/* alterar para grafico verdadeiro */}
      <div className="container-chart">
        <Doughnut data={data} options={options} />
      </div>
      {/*  */}

      <div className="container-footer">
        <div className="container-info-values">
          <span className="container-info-values-label">Total restante</span>
          <span className="container-info-values-value">
            {controller.formatNumber(props.restante)}
          </span>
        </div>

        <div className="container-info-values">
          <span className="container-info-values-label">Total atingido</span>
          <span className="container-info-values-value">
            {controller.formatNumber(props.atingido)}
          </span>
        </div>
      </div>
    </Card>
  );
};

VolumeSacas.propTypes = {
  total: PropTypes.number.isRequired,
  restante: PropTypes.number.isRequired,
  atingido: PropTypes.number.isRequired,
  cols: PropTypes.object,
};

export default VolumeSacas;
