import React, { useEffect, useState } from "react";
import Card from "../Card";
import "./style.scss";
import PropTypes from "prop-types";

const MiniCard = props => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 100);
  }, []);
  // alert(props.url);
  return (
    <Card
      className={
        "mini-card" + (visible ? " show" : "") + (props.animatePath ? " anime-svg-path" : "")
      }
      cols={{
        xl: 3,
        lg: 4,
        md: 6,
        sm: 6,
        xs: 12,
      }}
      linkProps={{
        className: "mini-card-link",
        href: props.url || "#/",
      }}
    >
      <div className="container-icon">
        {props.icon && typeof props.icon !== "string" ? (
          <props.icon style={{ fill: props.color, className: props.iconClass }} />
        ) : (
          <img src={props.icon} alt="Imagem mini card" />
        )}
      </div>

      <div className="container-description">
        <div className="valor" style={{ color: props.color }}>
          {props.valor}
        </div>
        <div className="name">{props.title}</div>
      </div>
      <div className="dots">...</div>
      {props.children}
    </Card>
  );
};

MiniCard.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default MiniCard;
