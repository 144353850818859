import React from "react";

import Header from "../Header";
import Loading from "../Loading";
import PropTypes from "prop-types";
import Menu from "../Menu";
import "./style.scss";

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.onClickCancelar = this.onClickCancelar.bind(this);
  }

  async onClickCancelar() {
    let redirect = true;
    if (this.props.onClickCancelar) {
      redirect = await this.props.onClickCancelar();
    }

    if (redirect) {
      window.location.href = "#/" + this.props.name + "/lista";
    }
  }

  componentDidMount() {
    if (this.props.formState) {
      document.body.classList.add("onForm");
      document.querySelector("html").classList.add("onForm");
    }
  }

  render() {
    return (
      <div className={"container-principal " + this.props.className}>
        <Loading loading={this.props.loading} />
        {!this.props.noMenu && <Menu />}
        <div
          className={
            "conatainer-header-middle " +
            (this.props.noMenu && this.props.noHeader ? " full-width " : "") +
            (this.props.noMargin ? " no-margin " : "")
          }
        >
          {!this.props.noHeader && <Header location={this.props.location} />}
          <div className={"container-page " + (this.props.noPadding ? " no-padding " : "")}>
            {this.props.containerTitle && (
              <h4 className="container-title-page">
                <span className="title-page">{this.props.titlePage}</span>
                {this.props.containerTitle}
              </h4>
            )}
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }

  static propTypes = {
    loading: PropTypes.bool,
    location: PropTypes.object,
    titlePage: PropTypes.string,
    children: PropTypes.element,
    onClickCancelar: PropTypes.func,
    onClickSave: PropTypes.func,
    name: PropTypes.string,
    formState: PropTypes.object,
    noMenu: PropTypes.bool,
  };
}

export default Page;
