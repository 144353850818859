import ServiceController from "../../components/ServiceController";

export default class CprService extends ServiceController {
  constructor() {
    super("cpr");
  }

  async listar(cache) {
    return this.fetchData(this.tableName, {
      url: "cprs/",
      type: "get",
      cache,
    });
  }

  salvar(data) {
    return this.fetchData(this.tableName, {
      url: "cprs/",
      type: "post",
      data,
      cache: false,
    });
  }

  async listaAtribuir() {
    return this.fetchData(this.tableName, {
      url: "cprs/listaAtribuir",
      type: "get",
      cacheTime: 2,
    });
  }
}
