import ServiceController from "../../components/ServiceController";

export default class OperacoesService extends ServiceController {
  constructor() {
    super("acompanhamento");
  }

  getDataFromCpr(cprId = "", safra = "", geral = false) {
    return this.fetchData(this.tableName, {
      url: this.path + `/getdata-cards-operacoes?cprId=${cprId}&geral=${geral}&safra=${safra}`,
      type: "get",
    });
  }

  getDataStatistics(cprId = "", safra = "", geral = false) {
    return this.fetchData(this.tableName, {
      url: this.path + `/getdata-statistica?cprId=${cprId}&geral=${geral}&safra=${safra}`,
      type: "get",
    });
  }
}
