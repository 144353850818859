import localforage from "localforage";
import Swal from "sweetalert2";
import { moment } from "../utils/TimeZone";
import { setlastStatus } from "../pages/Monitor/components/StatusNetwork";
export default class ServiceController {
  constructor(name) {
    this.path = this.tableName = name;
    this.urlserver = "";
    this.dev = process.env.NODE_ENV === "development";
    this.urlserver = this.dev ? "http://localhost:8080" : "/_server";

    this.usuarioLogado = JSON.parse(sessionStorage.getItem("usuarioLogado")) || {};
    if (!this.usuarioLogado || !this.usuarioLogado.token) {
      Swal.fire({
        toast: true,
        // title: "Oops!",
        text: "Faça o login para acessar a plataforma",
        timer: 5000,
        position: "top-right",
      });
      window.location = "#/login";
    }
    if (this.usuarioLogado && this.usuarioLogado.role === 2) {
      window.location = "#/monitor/monitoramentos";
    }
  }

  /**
   *
   * @param {*} key
   * @param {{
      type: "post" | "get" | "head",
      url: string,
      data: String | {},
      params: {},
      cache: boolean,
      cacheInfinito: boolean,
      clearCache: boolean,
      silently: boolean,
      throwError: boolean,
      cacheTime: number,
      verifyStatus: boolean
    }} param1
   */
  async fetchData(
    key = this.tableName,
    {
      url,
      type = "post",
      data,
      params = {},
      cache = true,
      cacheInfinito = false,
      clearCache = false,
      silently = false,
      throwError = false,
      cacheTime = 5,
      verifyStatus = false,
    },
  ) {
    if (this.usuarioLogado || this.usuarioLogado.token) {
      this.usuarioLogado = JSON.parse(sessionStorage.getItem("usuarioLogado")) || {};
    }

    if (
      !url.includes("login") &&
      (!this.usuarioLogado || !this.usuarioLogado._id || !this.usuarioLogado.token)
    ) {
      return { error: true };
    }

    let dataResponse = null;
    key += "-" + url + (params ? JSON.stringify(params) : "") + "-" + this.usuarioLogado._id;
    if (type !== "post" && cache) {
      dataResponse = await localforage.getItem(key);
      const diff = dataResponse ? moment(moment()).diff(dataResponse.date, "m") : 0;
      if (dataResponse && diff >= cacheTime && !cacheInfinito) {
        let online = true;

        if (verifyStatus) {
          online = await this.checkStatus(true);
        }

        if (online) {
          dataResponse = null;
        } else {
          dataResponse = dataResponse.data;
        }
      } else if (dataResponse) {
        dataResponse = dataResponse.data;
      }
    }

    if (!dataResponse) {
      try {
        this.headers = new Headers();
        this.headers.append("Content-type", "application/json");
        if (this.usuarioLogado && this.usuarioLogado.token) {
          this.headers.append("Authorization", `Token ${this.usuarioLogado.token}`);
        }

        const resp = await fetch(`${this.urlserver}/${url}`, {
          method: type,
          mode: "cors",
          body: type === "post" && data ? JSON.stringify(data) : undefined,
          headers: this.headers,
        });

        dataResponse = await resp.json();
        setlastStatus(true);
        if (type !== "post") {
          localforage.setItem(key, { data: dataResponse, date: Date.now() });
        } else {
          // significa que foi feito gravação de dados
          await localforage.removeItem(key); // faz com que o banco local seja atualizado da proxima vez
        }

        if (clearCache) {
          this.clearCache(this.tableName);
        }

        if (!dataResponse) {
          dataResponse = [];
        } else if (!(dataResponse instanceof Array) && dataResponse.error) {
          // dataResponse = { error: false, data: [] };
          throw new Error(dataResponse.msg);
        }
      } catch (e) {
        dataResponse = type === "post" ? { error: true } : [];
        if (!silently) {
          Swal.fire({
            type: "warning",
            title: "Oops!",
            html: `Falha na requisição, aguarde um instante e tente novamente mais tarde, ou entre em contato conosco. <br /><br />${
              e ? JSON.stringify(e.stack, null, 4).bold() : ""
            }`,
            allowOutsideClick: false,
            confirmButtonText: "Ok, entendi",
          });
        }

        if (throwError) {
          throw e;
        }
      }
    }

    return dataResponse;
  }

  async getData(key = this.tableName) {
    let data = await localforage.getItem(key);
    if (!data) {
      data = [];
    }

    return data;
  }

  async setData(key, value) {
    if (!value) {
      value = key;
      key = this.tableName;
    }

    if (!(value instanceof Array)) {
      console.warn(
        "geralmente o valor passado para funçao setData deve ser uma lista, por favor verifique",
      );
    }

    await localforage.setItem(key, value);
    return value;
  }

  async listar(cache = false) {
    let lista = await this.getData();
    if (!lista) {
      lista = [];
    }

    return lista;
  }

  async sync(
    urlSalvar = this.path + "/salvar",
    urlListar = this.path + "/listar",
    silently = true,
    onSync,
  ) {
    /**
     * @type {[{}]}
     */
    let listaOriginal = await this.listar();
    let lista = listaOriginal.filter(e => e.sync);
    let i = 0,
      len = lista.length,
      error = false;

    for (; i < len; i++) {
      const element = lista[i];
      try {
        const resp = await this.fetchData(undefined, {
          url: urlSalvar,
          data: element,
          cache: false,
          type: "post",
          silently,
          throwError: true,
        });

        if (!resp.error) {
          element.sync = false; // se deu certo desmarca a sincronizaçao para esse item
        } else {
          error = true;
          console.error(resp);
        }
      } catch (e) {
        error = true;
        console.error(e);
        if (silently) {
          Swal.fire({
            toast: true,
            position: "top-right",
            timer: 10000,
            html: `Falha ao sincronizar alguns itens, <br>tentaremos novamente mais tarde!<br> Se o problema persistir consulte o desenvolvedor!<br/>
              Dados do desenvolvedor: <b>${e.message}</b>`,
          });
        }
      }
    }

    if (!error) {
      // força uma atualização silenciosa do cache local
      listaOriginal = await this.fetchData(this.tableName, {
        url: urlListar,
        cache: false,
        type: "get",
        silently: true,
      });
    }

    await this.setData(this.tableName, listaOriginal);

    if (onSync) {
      onSync.call(this, listaOriginal);
    }
  }

  listarServer(url = "/") {
    return this.fetchData(this.tableName, {
      url: this.path + url,
      type: "get",
    });
  }

  async checkStatus(force = false) {
    let resp = { ok: false };
    try {
      resp = await this.fetchData("status", {
        cacheTime: force ? 0 : 1,
        type: "get",
        url: "ping",
        silently: true,
        throwError: true,
      });
    } catch (e) {
      resp.ok = false;
    }

    return !!resp.ok;
  }

  async clearCache(path) {
    const keys = await localforage.keys();
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (key.includes(path)) {
        await localforage.removeItem(key);
      }
    }
  }
}
