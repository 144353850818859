import React from "react";
import "./Loading.scss";

export default function Loading(props) {
  return (
    <div className={"container-loading " + (props.loading ? "show" : "")}>
      <div className={"container-loading-overflow"}>
        <div className="bar">
          <div className="circle" />
          <p>{props.texto}</p>
        </div>
      </div>
    </div>
  );
}

Loading.defaultProps = {
  texto: "Carregando",
};
