import React, { useState, useEffect } from "react";
import "../../../components/Header/styleapp.scss";
import "./../style.scss";
import FormBuilder from "../../../components/FormBuilder";
import Page from "../../../components/Page";
import AcompanhamentoController from "./controller";
import { moment } from "../../../utils/TimeZone";
import produtos from "../../../utils/produtos";

const controller = new AcompanhamentoController();
const FormAcompanhamento = props => {
  const jsondataState = {
    observacoes: "",
    horaInicial: moment().toDate(),
    horaFinal: moment().toDate(),
    dataHora: moment().toDate(),
    horaSaida: moment().toDate(),
    liberado: false,
    tipoArmazenamento: "",
    tipoVolume: "",
    tipo: props.tipo,
    volumeSacas: "",
    produtividade: "",
    pesoKg: "",
    condutor: "",
    outraPlaca: "",
    placaCavalo: "",
    localEntrega: "",
    placaCarreta: "",
    modeloCaminhao: "",
    volume: "",
    fardoes: "",
    fardinhos: "",
    areaColhida: "",
    valorBobinas: "",
    talhaoColhido: "",
    naoHouveColheita: false,
    colhedeirasProprias: "",
    colhedeirasTerceirizadas: "",
  };

  const initialState = {
    codigo: null,
    cultura: "",
    ...jsondataState,
  };
  const [state, setState] = useState(initialState);

  const handleChange = controller.handleChange(setState, () => state);
  const fields = controller.getFieldList(props, state);

  useEffect(() => {
    if (props.itemSelecionado.id) {
      const cultura = (
        produtos.filter(e => e.value === Number(props.itemSelecionado.cpr.produto))[0] || {
          label: "Não identificado",
        }
      ).label;
      setState(s => ({ ...s, cultura }));
    }
  }, [props.itemSelecionado]);

  useEffect(() => {
    if (props.data) {
      setState(s => ({ ...s, ...props.data }));
    }
  }, [props.data]);

  const onSave = async () => {
    if (props.beforeSave) {
      props.beforeSave();
    }

    let objSave = {};
    // debugger;
    Object.keys(jsondataState).forEach(key => {
      objSave[key] = state[key];
    });
    try {
      await controller.salvar(
        {
          codigo: state.codigo,
          jsondata: objSave,
          id: state.id,
          id_usuario_inclusao:
            state.id_usuario_inclusao || props.usuarioLogado.id || props.usuarioLogado._id,
          atribuicao_id: props.itemSelecionado.id,
        },
        () => {
          if (props.reloadList) {
            props.reloadList();
            controller.service.total(false);
          }
        },
        props.inside || props.readOnly,
      );

      props.closeModal();
    } catch (e) {
      if (props.afterSave) {
        props.afterSave({ error: true, ...e });
      }
    }
  };

  useEffect(() => {
    let sacas = 0,
      produtividade = 0;

    if (state.volume) {
      sacas = toNumber(state.volume) / 60;
      sacas = sacas.toFixed(0);
      sacas = toString(sacas);
    }

    if (props.tipo === 0 && state.volume && state.areaColhida) {
      produtividade = sacas / toNumber(state.areaColhida);
      produtividade = produtividade.toString().replace(".", ",");
    }

    setState(s => ({ ...s, produtividade, volumeSacas: sacas }));
    // eslint-disable-next-line
  }, [state.volume, state.areaColhida]);

  const toNumber = n => {
    return n ? Number(n.replace(/[.]/g, "").replace(/[,]/g, ".")) : 0;
  };

  const toString = n => {
    return n ? n.replace(/[,]/g, ".").replace(/[.]/g, ",") : "0";
  };

  return !props.inside ? (
    <Page style={{ marginTop: 55, padding: 10 }} noHeader noMenu>
      <FormBuilder
        state={state}
        handleChange={handleChange}
        onSaveButtonClick={onSave}
        fields={fields}
      />
    </Page>
  ) : (
    <FormBuilder
      state={state}
      handleChange={handleChange}
      onSaveButtonClick={onSave}
      // saveButton={!props.readOnly}

      fields={fields}
    />
  );
};

FormAcompanhamento.defaultProps = {
  readOnly: false,
  inside: false,
};

export default FormAcompanhamento;
