import { USUARIO_LOGADO } from "./actionTypes";

const initialState = {
  usuarioLogado: { nome: "", role: 0, id: null, token: "a76sda78s6d78", imagemSrc: null },
  version: "v0.5.11 - alpha",
};

export const SessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case USUARIO_LOGADO:
      return {
        ...state,
        usuarioLogado: action.payload,
      };
    default:
      return state;
  }
};
