import { USUARIO_LOGADO, FORM_STATE, ITEM_SELECIONADO } from "./actionTypes";

export const setUsuarioLogado = value => ({
  type: USUARIO_LOGADO,
  payload: value,
});

export const setItemSelecionado = value => ({
  type: ITEM_SELECIONADO,
  payload: value,
});

export const setFormState = value => ({
  type: FORM_STATE,
  payload: value,
});

//
// manter aqui apenas açoes do redux
//
// export const setCookie = (cname, cvalue, exdays) => {
//   var d = new Date();
//   d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
//   var expires = "expires=" + d.toUTCString();
//   document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
// };

// export const getCookie = cname => {
//   var name = cname + "=";
//   var decodedCookie = decodeURIComponent(document.cookie);
//   var ca = decodedCookie.split(";");
//   for (var i = 0; i < ca.length; i++) {
//     var c = ca[i];
//     while (c.charAt(0) === " ") {
//       c = c.substring(1);
//     }
//     if (c.indexOf(name) === 0) {
//       return c.substring(name.length, c.length);
//     }
//   }
//   return "";
// };
