import ServiceController from "../../../components/ServiceController";

export default class AcompanhamentoService extends ServiceController {
  constructor() {
    super("acompanhamento");
  }

  total(cache = true) {
    return this.fetchData(undefined, {
      url: this.path + "/total",
      type: "get",
      cache: cache,
      cacheTime: 1,
      silently: true,
    });
  }

  /**
   *
   * @param {String} login
   * @returns {Promise<[{id: number, nome: string}]>} _
   */
  buscarPorLogin(login) {
    return this.fetchData(undefined, {
      url: this.path + "/?login=" + login,
      type: "get",
      cache: false,
    });
  }

  listarParaAutorizar() {
    return this.fetchData(undefined, {
      url: this.path + "/listarParaAutorizar",
      type: "get",
      cacheTime: 1,
    });
  }

  remover(id) {
    return this.fetchData(undefined, {
      url: this.path + "/remover/?id=" + id,
      type: "post",
      cache: false,
      clearCache: true,
    });
  }
}
