import React, { useState, useEffect } from "react";
import Card from "../../../components/Card";
import { Bar } from "react-chartjs-2";
import { FormControl, Select, OutlinedInput, MenuItem } from "@material-ui/core";

const VolumeTon = props => {
  const colors = [];
  let items = [];
  let labels = ["1", "2", "3", "4"];
  let months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  if (props.ton) {
    items = props.ton
      .map((ton, i) => {
        if (i >= props.ton.length - 4) {
          colors.push("#a3a0fb");
          // labels.push((i + 1).toString());
          return ton;
        } else return undefined;
      })
      .filter(el => {
        return el !== undefined;
      });
    // console.log(items);
  }
  const data = {
    labels: labels,
    datasets: [
      {
        backgroundColor: colors,
        data: items,
      },
    ],
  };

  const options = {
    legend: {
      display: false,
    },
    tooltips: {
      // enabled: false,
    },
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          barPercentage: 0.8,
          maxBarThickness: 28,
        },
      ],
    },
  };

  const [filtro, setFiltro] = useState(new Date().getMonth());

  useEffect(() => {
    props.handleChange(filtro); // 0 = janeiro
    // eslint-disable-next-line
  }, [filtro]);

  function handleChange(event) {
    setFiltro(event.target.value);
  }

  return (
    <Card
      className="card-volume-tom"
      title={
        <div style={{ display: "flex", position: "relative", justifyContent: "space-between" }}>
          <span>Toneladas Colhidas x Semana</span>
          <div style={{ textTransform: "initial" }}>
            <FormControl variant="outlined">
              <Select
                value={filtro}
                onChange={handleChange}
                input={<OutlinedInput name="age" id="outlined-age-simple" />}
              >
                <MenuItem value="">
                  <em>Selecione</em>
                </MenuItem>
                {months.map((m, i) => {
                  if (i <= new Date().getMonth()) {
                    return (
                      <MenuItem value={i}>
                        {m} / {new Date().getFullYear()}
                      </MenuItem>
                    );
                  } else return undefined;
                })}
              </Select>
            </FormControl>
          </div>
        </div>
      }
      cols={props.cols || { md: 12, lg: 6 }}
    >
      <div className="container-chart">
        <Bar data={data} options={options} />
      </div>
    </Card>
  );
};
VolumeTon.propTypes = {
  ton: [],
};
export default VolumeTon;
