import React from "react";
import Card from "../../../components/Card";
import { Doughnut } from "react-chartjs-2";
// import { FormControl, Select, OutlinedInput, MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";

function getData(colhido = 2500, total = 5000) {
  const color = "#ffda83";
  const notEmpty = "#f0f2f8";

  return {
    // label: "teste",
    backgroundColor: [color, notEmpty],
    borderWidth: 0,
    data: [colhido, total - colhido],
  };
}

/**
 *
 * @param {{total: Number, colhido: Number}} props
 */
const AreaColhidaCpr = props => {
  const data = {
    labels: ["Total colhido", "Restante"],
    datasets: [{ ...getData(props.colhido, props.total) }],
  };

  let percent = props.total ? ((props.colhido * 100) / props.total).toFixed(2) : "0";
  if (percent.split(".")[1] === "00") {
    percent = Math.round(percent);
  }

  const options = {
    responsive: true,
    rotation: -1.0 * Math.PI, // start angle in radians
    circumference: Math.PI, // sweep angle in radians
    maintainAspectRatio: false,
    middleText: percent + " %",
    cutoutPercentage: 60,
    tooltips: {
      // enabled: false,
    },
    legend: {
      display: false,
    },
  };
  return (
    <Card
      title={
        <div style={{ display: "flex", position: "relative", justifyContent: "space-between" }}>
          <span>Área colhida da CPR (HA)</span>
          {/* <div style={{ textTransform: "initial" }}>
            <FormControl variant="outlined">
              <Select
                value={10}
                // onChange={handleChange}
                input={<OutlinedInput name="age" id="outlined-age-simple" />}
              >
                <MenuItem value="">
                  <em>Filtros</em>
                </MenuItem>
                <MenuItem value={10}>00925867</MenuItem>
              </Select>
            </FormControl>
          </div> */}
        </div>
      }
      cols={{ xl: props.xl || 12, lg: props.lg || 12 }}
      style={{ maxHeight: 350 }}
    >
      <div className="container-chart">
        <Doughnut options={options} data={data} style={{ marginTop: -20 }} />
      </div>
    </Card>
  );
};

AreaColhidaCpr.propTypes = {
  colhido: PropTypes.number,
  total: PropTypes.number,
};

export default AreaColhidaCpr;
