import React from "react";
import { default as Bcard } from "react-bootstrap/Card";
import "./style.scss";
import Col from "react-bootstrap/Col";

const Card = props => {
  return (
    <Col {...props.cols}>
      <a {...props.linkProps}>
        <Bcard className={props.className} style={props.style} onClick={props.onClick}>
          {props.title && <Bcard.Header>{props.title}</Bcard.Header>}
          {props.children}
        </Bcard>
      </a>
    </Col>
  );
};

export default Card;
